import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom'

// Sections
import { Loader } from 'components/Loader';
import { PersonTile } from './PersonTile';
import { PersonOverlay } from './PersonOverlay'
// Queries
import { peopleQuery } from "utils/sanity/sanity-queries"

// Images
import you from 'img/img-people-you.jpg'


// Utils
import { getData } from 'utils/sanity/'

// Style
import './index.scss'
import { over } from 'lodash';

export const PeopleGrid = ({setModalOpen}) => {
  const [data, setData] = useState([]);
  const [people, setPeople] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [overlay, setOverlay] = useState({
    name: '',
    title: '',
    img: '',
    open: false,
  });
  let { slug: urlSlug } = useParams()
  let navigate = useNavigate();


    // Get the data from Sanity
    useEffect(() => {
      async function fetchData() { 
        try {
          const dataResponse = await getData(peopleQuery)
          setData(dataResponse)
          setPeople(dataResponse.people)
          setLoaded(true)
          console.log(dataResponse)
        }
        catch (err) {
          console.log('fetch failed', err);
          throw err
        }
      }
      fetchData()
    }, []);

    console.log(people)
    const peopleSlugs = people.map((person) => { return person.slug })
    const slugMatch = peopleSlugs.includes(urlSlug)
    
    const overlayPerson = people.find(({ slug }) => {
      return slug === urlSlug })

        //check to see if the slug exists in our project, otherwise send us home
        useEffect(() => {
          if (loaded){
            if (slugMatch) {
              (document.title = `OpenBCI | ${overlayPerson.name}`)
              setOverlay({name: overlayPerson.name, position: overlayPerson.position, bio: overlayPerson.bio, img: overlayPerson.img, open: true})
            } else {
              navigate("/about")
              setOverlay({open: false})
            }
          }
        }, [loaded, navigate, slugMatch, overlayPerson])

        useEffect(() => {
          if(overlay.open) {
            setModalOpen(true)
          } else {
            setModalOpen(false)
          }
        }, [setModalOpen, overlay.open]);
  
    return (
      <section className={`people-section`}>
        {overlay.open && <PersonOverlay 
        name={overlay.name}
        position={overlay.position}
        img={overlay.img}
        bio={overlay.bio}
        closeButtonAction={() => {setOverlay({open: false}); navigate('/about')}}
        />}
        <div className='people-grid'>
          {data.people && data.people.map((person, i) =>           
            <PersonTile 
            onClick={() => {setOverlay({name: person.name, position: person.position, bio: person.bio, img: person.img, open: true}); navigate(`/about/${person.slug}`)}}
            name={person.name}
            position={person.position}
            img={person.thumb}
            key={i}
            />
          )}
          <Link to='/careers'>
            <PersonTile 
              name="You!"
              position="See Open Roles"
              url='https://pbs.twimg.com/media/Cc-cxdZWEAEZy8k.jpg'
              img={you}
              key={11}
            />
          </Link>
        </div>
      </section>
  )
}