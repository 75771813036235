import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { ScrollToTop } from 'utils/ScrollToTop';

// Sections
import { Subscribe } from 'components/Subscribe'
import { Loader } from 'components/Loader';
import { PageHero } from 'components/PageHero';
import { PageOverhang } from 'components/PageOverhang';

// Style
import './index.scss'
import { CaseStudiesContext } from 'contexts/CaseStudiesContext';

export const CaseStudies = () => {
  const [data] = useState([]);
  const { loaded} = useContext(CaseStudiesContext);
  let { slug: urlSlug } = useParams()
  let navigate = useNavigate();
  
  const pageSlugs = data.map((page) => { return page.slug })

  const slugMatch = pageSlugs.includes(urlSlug)
  
    const page = data.find(({ slug }) => {
      return slug === urlSlug })

  //check to see if the slug exists in our project, otherwise send us home
  useEffect(() => {
    if (loaded){
      if (slugMatch) {
        (document.title = `OpenBCI Case Study | ${page.hero.heroEyebrow}`)
      } else {
        navigate("/")
      }
    }
  }, [loaded, navigate, slugMatch, page])
  

  

  return ( !loaded ?
    <Loader /> 
    :
    <div className={`home-page`} >
      <ScrollToTop />
      {page.hero && <PageHero 
        eyebrow={page.hero.heroEyebrow} 
        header={page.hero.heroHeader}
        image={page.hero.heroImg}
        hasButton={page.hero.hasButton}
        button={page.hero.button}
        tall={false}
      />
      }
      <section className="page-content-wrapper">
        {page.pageOverhang &&
          <PageOverhang 
            title={page.pageOverhang.title}
            body={page.pageOverhang.body}
            sidenote={page.pageOverhang.sidenote}
          />
        }
        </section>
      <Subscribe />
    </div>
  )
}