import React, { useState, useEffect } from 'react';
import { getData } from 'utils/sanity/'

// Queries
import { caseStudyQuery } from "utils/sanity/sanity-queries"

const CaseStudiesContext = React.createContext({});

const CaseStudiesProvider = (props) => {
  // Set two loading states for without and with data
  const [loaded, setLoaded] = useState(false);
  // Set a project state
  const [caseStudies, setCaseStudies] = useState([]);


  // Get the data from Sanity
  useEffect(() => {
    async function fetchData() { 
      try {
        // Get Case Studies
        const caseStudyResponse = await getData(caseStudyQuery)
        setCaseStudies(caseStudyResponse)
        setLoaded(true)
      }
      catch (err) {
        console.log('fetch failed', err);
        throw err
      }
    }
    fetchData()
  }, []);

  return (
    <CaseStudiesContext.Provider value={{ loaded, caseStudies }}>
      {props.children}
    </CaseStudiesContext.Provider>
  );
}

export { CaseStudiesContext, CaseStudiesProvider };