import React, { useEffect, useState } from 'react';

// Sections
import { Subscribe } from 'components/Subscribe'
import { Loader } from 'components/Loader';
import { PageOverhang } from 'components/PageOverhang';
// Queries
import { peopleQuery } from "utils/sanity/sanity-queries"

// Utils
import { getData } from 'utils/sanity/'

// Style
import './index.scss'
import { PeopleGrid } from './PeopleGrid';
import { PageHero } from 'components/PageHero';

export const About = () => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);


    // Get the data from Sanity
    useEffect(() => {
      async function fetchData() { 
        try {
          const dataResponse = await getData(peopleQuery)
          setData(dataResponse)
          setLoaded(true)
          document.title = `OpenBCI | About`
        }
        catch (err) {
          console.log('fetch failed', err);
          throw err
        }
      }
      fetchData()
    }, []);
  
    return ( !loaded ?
      <Loader /> 
      :
      <div className={`about-page`} style={{overflow: modalOpen ? 'hidden' : 'auto'}} >
        <section className="page-content-wrapper">
        <PageHero 
          eyebrow={data.hero.heroEyebrow}
          header={data.hero.heroHeader}
          image={data.hero.heroImg}
        />
        <PageOverhang 
          title={data.pageOverhang.title}
          body={data.pageOverhang.body}
          sidenote={data.pageOverhang.sidenote}
        >
          </ PageOverhang>
          <div className='page-content-container'>
            <h2>Meet the team</h2>
            <div className='rule-orange' />
          </div>
        </section>
        <PeopleGrid 
          setModalOpen={setModalOpen}
        />
        <div className ='page-content-wrapper'>
          <Subscribe />
        </div>
      </div>
  )
}