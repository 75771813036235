import React, { useEffect, useState, useContext } from 'react';

// Style
import './index.scss'

export const PersonTile = ({name, position, img, onClick}) => {
  
    return (
      <div className={`person-tile`} onClick={onClick}>
        <div className='person-tile-info'> 
          <h4>{name}</h4>
          <div className='eyebrow'>{position}</div> 
        </div>
        <div className='person-tile-image' style={{backgroundImage:`url(${img})`}} /> 
      </div>
  )
}