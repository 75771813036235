import { BodyContent } from 'components/Sanity';
import React, { useEffect, useState, useContext } from 'react';

// Style
import './index.scss'

export const PersonOverlay = ({name, position, img, bio, closeButtonAction}) => {
    return (
      <>
        <div className='person-overlay-container'>
        <div className={`person-overlay`} >
          <button className='close-button' onClick={closeButtonAction}>
            <svg width="24" height="24" xmlns="http:// www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"/></svg>
          </button>
          <img className='person-overlay-image' src={img} alt='headshot'/> 
          <div className='person-overlay-info'> 
            <h4>{name}</h4>
            <div className='eyebrow'>{position}</div>
            <div className='rule-orange' />
            {bio && <BodyContent blocks={bio} />}
          </div>
        </div>
        </div>
      <div className='overlay-scrim' onClick={closeButtonAction}/>
      </>
  )
}