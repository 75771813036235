import React from 'react'
import appConfig from 'app.config'
import BlockToReact from '@sanity/block-content-to-react'
 
// Components
import { HeaderAndBody } from 'components/Portfolio/HeaderAndBody'
import { FullWidthImage } from 'components/Portfolio/FullWidthImage'
import { Button } from './BlockButton'
import { BlockImage } from './BlockImage'
import { BlockRule } from './BlockRule'


// Style
import './index.scss'

    
export const BodyContent = ({ className, blocks, ...props }) => {
  return(
    <div className={className}>
      <BlockToReact
        blocks={blocks}
        projectId={appConfig.sanity.id}
        dataset={appConfig.sanity.dataset}
        serializers={{ 
          marks: {
            link: ({mark, children}) => {
              // Read https://css-tricks.com/use-target_blank/
              const { blank, href } = mark
              return blank ?
                <a href={href} target="_blank" rel="noreferrer">{children}</a>
                : <a href={href}>{children}</a>
            }
          },
          types: { 
            button: Button, 
            headerAndBody: HeaderAndBody,
            fullWidthImage: FullWidthImage,
            blockImage: BlockImage,
            blockRule: BlockRule
          }
        }}
        {...props}
      />
    </div>
  )
}