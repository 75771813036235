const getBlockContent = fieldName => `${fieldName}[]{
  ...,
  ...select(
    _type == "blockImage" => {
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "oneOff" => {
      ...,
      options
    }
  ),
  ...select(
    _type == "twoPicture" => {
      "image1": image1.asset -> url,
      "image2": image2.asset -> url
    }
  ),
  ...select(
    _type == "fullWidthImage" => {
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "fullGridImage" => {
      ...,
      "image": imagex.asset -> url,
      "backgroundColor": backgroundColor.hex
    }
  ),
  ...select(
    _type == "headerAndBody" => {
      ...,
      "backgroundColor": backgroundColor.hex
    }
  ),
  ...select(
    _type == "pullquote" => {
      ...,
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "thinGridImage" => {
      ...,
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "tileArray" => {
      ...,
      tileArray[]{
        ...,
        "image": image.asset -> url
      }
    }
  ),
  ...select(
    _type == "cropFullWidthImage" => {
      ...,
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "logoBlock" => {
      ...,
      "image": image.asset -> url
    }
  ),
}`

export const homepageQuery = `*[_type=="homepageContent"][0] | {
  hero {..., "heroImg": heroImg.asset -> url},
  callout{...,"image": image.asset -> url},
  homeCitations[]-> ,
  communityGrid{..., homeCommunityPosts[]{...,"image": image.asset -> url}},
  caseStudiesSection,
  testimonials[]{...,"image": image.asset -> url},
  shopFeature{shopFeatureMain{..., "image": image.asset -> url}, shopFeaturette[]{...,"image": image.asset -> url}},
  homeCaseStudiesGrid{..., categories[]{..., "img": img.asset -> url}}
}`

export const downloadsQuery = `*[_type=="downloadsContent"][0] | {
  hero {..., "heroImg": heroImg.asset -> url},
  pageOverhang,
  downloadsSections[]{..., files[]{..., "icon": icon.asset -> url}},
}`

export const donateQuery = `*[_type=="donateContent"][0] | {
  hero {..., "heroImg": heroImg.asset -> url},
  pageOverhang,
}`

export const citationsQuery = `*[_type=="citationsContent"][0] | {
  hero {..., "heroImg": heroImg.asset -> url},
  pageOverhang,
  "citations": *[_type=="citation"]
}`

export const careersQuery = `*[_type=="careersContent"][0] | {
  hero {..., "heroImg": heroImg.asset -> url},
  pageOverhang,
  careers[_type=="career" && active == true]{..., "slug": slug.current},
  // careers[]{..., "slug": slug.current},
  'fetchedCommunityGrid': *[_type=="homepageContent"][0]{communityGrid{..., homeCommunityPosts[]{..., "image": image.asset -> url}}}
}`

export const peopleQuery = `*[_type=="peopleContent"][0] | {
  hero {..., "heroImg": heroImg.asset -> url},
  people[]{...,"img": headshot.asset -> url, "thumb": thumb.asset -> url, "slug": slug.current},
  pageOverhang,
}`

export const pagesQuery = `*[_type=="page"] | {
  hero {..., "heroImg": heroImg.asset -> url},
  "slug": slug.current,
  pageOverhang
}`

export const caseStudyQuery = `*[_type=="caseStudy"] | {
  hero {..., "heroImg": heroImg.asset -> url},
  "slug": slug.current,
  author,
  creationDate,
  pageOverhang {..., "body": ${getBlockContent('body')}},
  "article": ${getBlockContent('article')}
}`