import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';

// Utils
import { handleSubmit } from 'utils/handleSubmit';

// Style
import './index.scss'


export const DonorListSection = ({image, title, nickname, button, donors}) => {
  const stripe = useStripe();
  const style = !title ? {padding: 0, margin: 0, border: 'none'} : {}
  return (
    <div className={`donor-list-section`} key={title} >
      <div className="donor-title-bar">
        <img className='donor-title-image' src={image} alt='brainwave' />
        <h5><span className='donor-title'>{title}</span><span className='donor-nickname' style={style}>{nickname}</span></h5>
        <button onClick={()=> handleSubmit(stripe, button.priceId)} className='button' >{button.text}</button>
      </div>
      <div className='donor-list'>
        {donors.map((donor, i)=>
          <p key={i}>{donor}</p>
        )}
      </div>
    </div>
  )
}