import React from 'react';

// Style
import './index.scss'

export const PageHero = ({image, header, eyebrow, hasButton, button, tall}) => {

  return (
    <section className='page-hero' style={{backgroundImage: `url(${image})`, height: tall ? '80vh' : '50vh'}}>
      <div className='hero-eyebrow'>{eyebrow}</div>
      <h1>{header}</h1>
      <div className='rule-white' />
      {hasButton && <a className='button' href={button.url}>{button.text}</a>}
      <div className='hero-scrim' />
    </section>
  )
}