import React from 'react';

// Style
import './index.scss'

export const CaseStudiesGrid = ({eyebrow, title, categories}) => {

  return (
    <section className='case-studies-grid' >
    <div className='grid-item'>
      <div className='eyebrow'>{eyebrow}</div>
      <h2>{title}</h2>
    </div>
    {categories.map(({title, img, url}, i)=>
      <a className='grid-item' href={url} key={i} >
        <h4>{title}</h4>
        <div className='grid-item-image' style={{backgroundImage: `url(${img})`}} />
      </a>
    )}
  </section>
  )
}