import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import appConfig from 'app.config'

// Style
import s from './index.module.scss'

export const HeaderAndBody = ({ node }) => {
  
  const {header, body, backgroundColor, topMargin = true, bottomMargin = true} = node

  const serializer = {
    marks: {
      link: ({mark, children}) => {
        // Read https://css-tricks.com/use-target_blank/
        const { blank, href } = mark
        return blank ?
          <a href={href} target="_blank" rel="noreferrer">{children}</a>
          : <a href={href}>{children}</a>
      }
    },
    types: {
      block: props => (
        <p>
          {props.children}
        </p>
      )
    }
  }

  return(
    <div style={{backgroundColor: `${backgroundColor}`}}>
          <div className={s["header-and-body"]} 
                style={{
                  marginTop: topMargin ? '12%' : 'none',
                  marginBottom: bottomMargin ? '16%' : 'none'
                }}
          >
            <div className={s["header"]} >
              <h2>{header}</h2>
            </div>
            <div className={s["body"]} >
              <BlockContent 
                blocks={body} 
                projectId={appConfig.sanity.id}
                dataset={appConfig.sanity.dataset}
                serializers={serializer}
              />
            </div>

          </div>
    </div>
  )
}
