import React, { useEffect, useState } from 'react';
import { ScrollToTop } from 'utils/ScrollToTop';
import { useParams, useNavigate } from 'react-router-dom'
import { BodyContent } from 'components/Sanity/index'
import { getData } from 'utils/sanity';

// Queries
import { careersQuery } from "utils/sanity/sanity-queries"

// Sections
import { Subscribe } from 'components/Subscribe'
import { Loader } from 'components/Loader';
import { PageHero } from 'components/PageHero';
import { PageOverhang } from 'components/PageOverhang';

// Style
import './index.scss'

export const CareerPage = () => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  let { slug: urlSlug } = useParams()
  let navigate = useNavigate();

  useEffect(() => {
    async function fetchData() { 
      try {
        const dataResponse = await getData(careersQuery)
        setData(dataResponse.careers)
        setLoaded(true)
      }
      catch (err) {
        console.log('fetch failed', err);
        throw err
      }
    }
    fetchData()
  }, []);
  
  const pageSlugs = data.map((career) => { return career.slug })
  const slugMatch = pageSlugs.includes(urlSlug)
  
  const career = data.find(({ slug }) => {
    return slug === urlSlug })
    //check to see if the slug exists in our project, otherwise send us home
    useEffect(() => {
      if (loaded){
        if (slugMatch) {
          (document.title = `OpenBCI Career | ${career.position}`)
        } else {
          navigate("../careers", { replace: true });
        }
      }
    }, [loaded, navigate, slugMatch, career])

  return ( !loaded ?
    <Loader /> 
    :
    slugMatch && <div className={`career-page`} >
      <ScrollToTop />
      <section className="page-content-wrapper">
          <PageOverhang 
            title={career.position} 
            body={career.description}
            sidenote={{
              sidenoteTitle: 'Come innovate with OpenBCI',
              sidenoteButton: {
                url: `${career.link ?  career.link.url : `mailto:jobs@openbci.com?subject=Interested in the ${career.position} Position&body=[Instructions: Please include your resume, examples of past work, and an explanation of why you want to work at OpenBCI]`}`,
                text: `${career.link ? career.link.text : "Apply Now"}`
              }
            }}
            button={true}>
            <br/><br/> {/* hacky solution for now */}
            <h2>Primary Responsibilities</h2>
            <BodyContent blocks={career.responsibilities} />
            <h2>Qualifications</h2>
            <BodyContent blocks={career.qualifications} />
            <h2>Preferred Skills</h2>
            <BodyContent blocks={career.skills} />
            <h2>Benefits</h2>
            <BodyContent blocks={career.perks} />
            <a className='button' href={career.link ?  career.link.url : `mailto:jobs@openbci.com?subject=Interested in the ${career.position} Position&body=[Instructions: Please include your resume, examples of past work, and an explanation of why you want to work at OpenBCI]`}>{`${career.link ? career.link.text : "Apply Now"}`}</a>
        </PageOverhang>
        </section>
      <Subscribe />
    </div>
  )
}