import React, { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

// Utils
import { handleSubmit } from 'utils/handleSubmit';

// Style
import './index.scss'

export const DonationSection = ({ donationButtons}) => {
  const [amount, setAmount] = useState(5);
  const [priceId, setPriceId] = useState('price_1JYBP1Erb6Bpkgcnaw9slJBD');
  const stripe = useStripe();

  return (
    <>
      <section className='donation-section'>
        <div className='donation-section-orange'>
          <h3>Become a rockstar, super supporter, or OpenBCI enthusiast today!</h3>
          <div className='rule-white' />
          <div  className='donation-amount-buttons'>
            {donationButtons.map(({cost, sku}, i) =>
              <button className={`donation-amount-button ${cost === amount ? 'active' : ''}`} key={i} onClick={() => {setAmount(cost); setPriceId(sku);}}>
                {`$${cost}`}
              </button>
            )}
          </div>
          <div className='donation-bar'>
            <div className='donation-bar-field'>
              {`$${amount}`}
            </div>
            <button className='donation-bar-button' onClick={() => {handleSubmit(stripe, priceId)}}>Donate Today</button>
          </div>
        </div>
        <div className='donation-section-blue'>
          <div className='eyebrow'>$1000 platinum donor</div>
          <h3>Become a Platinum donor!</h3>
          <div className='rule-orange' />
          <p className='donation-copy'>Donations of any amount are welcome! If you think what we're doing is super awesome and would like to become a 'Platinum Donor' of OpenBCI, you can do so by donating $1000 or more. You will then be added to the list of Platinum Donors on our home page!</p>
          <div className='donation-bar'>
            <div className='donation-bar-field'>
              {`$1000`}
            </div>
            <button className='donation-bar-button' onClick={() => {handleSubmit(stripe, 'price_1JYBHVErb6Bpkgcn2KLokQJK'); console.log('yay')}}>Donate $1000</button>
          </div>
        </div>
      </section>
      <p className='donate-caveat'>*Please note that donations to OpenBCI are not tax-deductible.</p>
    </>
  );
};

