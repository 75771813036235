import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// Images
// import search from 'img/search.svg'

import './index.scss'

export function Footer() {
  const location = useLocation();
  return (
    <React.Fragment>
      <div className={`footer`}>
        <Link className="footer-logo-container" to='/'>
          <svg className='footer-logo' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 442 100" xmlSpace="preserve">
            <path id="NEW_LOGO" className="st0" d="M92.3,39.2l4.4-6.5l-1.4-3.3l-7.9-1.8c-0.8-1.3-1.7-2.6-2.6-3.8l1.7-7.8l-2.4-2.4l-8,1.4
              c-1.2-0.9-2.5-1.8-3.8-2.6l-1.4-7.8l-3.3-1.4l-6.8,4.4c-1.5-0.4-3-0.7-4.6-0.9L51.8,0h-3.5l-4.6,6.7c-1.5,0.2-3.1,0.5-4.6,0.8
              l-6.5-4.5l-3.3,1.4l-1.8,7.9C26.3,13.2,25,14,23.8,15L16,13.3l-2.4,2.4l1.4,8c-0.9,1.2-1.8,2.5-2.6,3.8L4.6,29l-1.4,3.3l4.4,6.8
              c-0.4,1.5-0.7,3-0.9,4.6L0,48.1v3.5l6.7,4.6c0.2,1.5,0.5,3.1,0.8,4.6l-4.4,6.5l1.4,3.3l7.9,1.8c0.8,1.3,1.7,2.6,2.6,3.8L13.3,84
              l2.4,2.4l8-1.4c1.2,0.9,2.5,1.8,3.8,2.6l1.4,7.8l3.3,1.4l6.8-4.4c1.5,0.4,3,0.7,4.6,0.9L48,100h3.5l4.6-6.7c1.5-0.2,3.1-0.5,4.6-0.9
              l6.5,4.5l3.3-1.4l1.8-7.9c1.3-0.8,2.6-1.7,3.8-2.6l7.8,1.7l2.4-2.4l-1.4-8.1c0.9-1.2,1.8-2.5,2.6-3.8l7.8-1.4l1.4-3.3l-4.4-6.8
              c0.4-1.5,0.7-3,0.9-4.6l6.7-4.3v-3.5l-6.7-4.6C92.9,42.3,92.6,40.8,92.3,39.2z M76,76.1c-14.4,14.4-37.7,14.4-52.1,0
              c-14.3-14.5-14.3-37.8,0-52.2C38.2,9.5,61.6,9.5,76,23.9C90.3,38.3,90.3,61.7,76,76.1L76,76.1z M49.5,30.9L49.5,30.9z M25.9,67.8
              L25.9,67.8z M26.6,68.1c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.2,0.7,0.1l0.2,0c0.3-0.1,0.7-0.2,1-0.3l0.2-0.1
              c0.4-0.1,0.7-0.2,1.1-0.3c0.2-0.4,0.7-0.6,1.2-0.4c0.4,0.2,0.6,0.7,0.4,1.2c-0.2,0.4-0.7,0.6-1.2,0.4c-0.1,0-0.1-0.1-0.2-0.1
              c-0.6,0.1-1.1,0.4-1.6,0.7c0.6,0.3,1.1,0.6,1.7,0.8c0.8,0.3,1.5,0.4,2.3,0.6c0.1,0,0.2,0,0.3,0c1.3,0.1,2.7-0.3,3.8-1
              c0-0.3,0.2-0.5,0.4-0.7c0.4-0.3,1-0.1,1.2,0.3c0.3,0.4,0.1,1-0.3,1.2c-0.2,0.1-0.5,0.2-0.8,0.1l-0.1,0c-0.1,0-0.1,0.1-0.2,0.1
              c-0.3,0.2-0.7,0.3-1,0.4c0.3,0.1,0.5,0.3,0.7,0.6c0.1,0.2,0.3,0.3,0.4,0.5c0.6,0.5,0.9,1.4,0.6,2.1c-0.2,1.1-0.5,2.2-0.7,3.3
              c-0.1,0.4-0.1,0.8-0.2,1.2c-0.2,0.5,0.1,1,0.6,1.2c0.6,0.1,1.3,0.2,1.9,0.3l0.1,0c0.3,0,0.6-0.2,0.7-0.5c0.1-0.2,0.1-0.3,0.2-0.5
              c0.2-0.6,0.5-1.3,0.7-1.9c-0.2,0.1-0.5,0.1-0.8,0.2c-0.1,0-0.1,0-0.2,0l-0.1,0c-0.3,0.4-0.8,0.5-1.3,0.2s-0.5-0.8-0.2-1.3
              c0.2-0.2,0.4-0.4,0.7-0.4c0.3,0,0.6,0.1,0.8,0.3c0.5-0.1,1.1-0.2,1.6-0.3c0.3-0.7,0.6-1.3,0.8-1.9c-0.5,0.2-1.1,0.3-1.6,0.4
              c-0.1,0-0.2,0-0.2,0h0c-0.3,0.4-0.8,0.6-1.2,0.3c-0.4-0.3-0.6-0.8-0.3-1.2c0.3-0.4,0.8-0.6,1.2-0.3c0.1,0.1,0.2,0.1,0.3,0.2
              c0.9-0.1,1.7-0.3,2.5-0.6c0.3-0.7,0.7-1.4,1-2c-0.8,0.4-1.7,0.6-2.6,0.8c-0.1,0-0.1,0-0.2,0l-0.1,0c-0.3,0.4-0.8,0.5-1.3,0.2
              s-0.5-0.8-0.2-1.3c0.2-0.2,0.4-0.4,0.7-0.4c0.3,0,0.6,0.1,0.8,0.3c1.6-0.2,3.1-0.7,4.3-1.6l0.1-0.1c0.4-0.3,0.9-0.5,1.4-0.6
              c1.3-0.1,2.4-0.7,3.4-1.6c-1.4,0.3-2.9,0.5-4.4,0.6c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.3,0.4-0.9,0.4-1.3,0.1
              c-0.4-0.3-0.4-0.9-0.1-1.3c0.2-0.2,0.5-0.3,0.8-0.3l0,0c0.3,0,0.5,0.1,0.6,0.4c1.8,0.1,3.7-0.2,5.4-0.7c-0.5-0.3-1-0.5-1.6-0.9
              l-0.2-0.1c-1.1-0.6-4.3,0.3-6.8,1.6c-0.4,0.2-0.9,0.5-1,0.5s-0.2,0.1-0.3,0.1c-0.2,0.1-0.5,0.2-0.7,0.3c0.1,0.1,0.3,0.2,0.4,0.4
              c0.1,0,0.2-0.1,0.3-0.1c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9v0c0-0.1,0-0.1,0-0.2L40,68.7L40,68.6
              c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.5-0.1l-0.1,0c-0.1,0-0.1,0-0.2,0l-0.8,0.1c-0.5,0-0.9,0.1-1.4,0.1l-1,0.1l-0.3,0
              c-0.6,0-0.6,0.1-0.6,0.1c0,0.1-0.1,0.2-0.1,0.4c0.1,0.2,0.2,0.4,0.2,0.6c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9
              c0-0.4,0.3-0.8,0.7-0.9c0.1-0.4,0-0.8-0.3-1.1c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0-0.3,0c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9
              s0.9,0.4,0.9,0.9v0c0,0.1,0,0.1,0,0.2c0.2,0.1,0.3,0.3,0.5,0.5c0.5,0.2,1,0.2,1.4,0.1l0.2,0c0.4,0,0.7-0.1,1.1-0.1
              c1.1-0.1,2.2-0.3,3.3-0.7l0.3-0.2c0.2-0.1,0.3-0.1,0.6-0.3l0.8-0.4c1.6-0.8,3.2-1.5,5-1.8l-0.2-0.1l-0.1-0.1
              c-0.4-0.3-0.9-0.5-1.3-0.7c-0.3-0.1-0.6,0-0.9,0c-0.3,0.1-0.7,0.3-1,0.4l-0.2,0.1c-1.1,0.5-2.2,0.8-3.4,0.9c-0.1,0-0.2,0-0.2,0h-0.1
              l-0.1,0l0,0c-0.3,0.4-0.9,0.5-1.3,0.2c-0.4-0.3-0.5-0.9-0.2-1.3c0.3-0.4,0.9-0.5,1.3-0.2c0,0,0.1,0,0.1,0.1h0.1
              c1.4,0,2.8-0.4,4.1-1.1c-1.4-0.4-3.2-0.8-4-0.9c-0.6-0.1-1.3-0.2-1.9-0.3c-0.3,0-0.7-0.1-1.3-0.1l-0.1,0c-0.1,0-0.3,0-0.4,0.1
              c-0.5,0.2-0.9,0.4-1.4,0.7l-0.3,0.2c-0.6,0.3-1.1,0.6-1.8,0.9l-0.1,0c-0.2,0.5-0.7,0.7-1.2,0.5c-0.5-0.2-0.7-0.7-0.5-1.2
              c0.1-0.3,0.3-0.5,0.6-0.5c0.3-0.1,0.5,0,0.7,0.1c0.7-0.2,1.4-0.5,2-1c-1.6-0.1-3.3,0.1-4.8,0.8c-1.6,0.9-3.4,1.5-5.2,1.9
              c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2,0.1,0.3,0.1,0.5c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.3,0.6,0.5,1c0.1,0.1,0.9,0.2,3.4-1.2
              c0.1-0.1,0.2-0.1,0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9
              c0,0.1,0,0.2,0,0.3l0,0c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0.1,0.4,0.2,0.7,0.1l0.1,0c0.8-0.1,1.7-0.3,2.6-0.4l0.1,0
              c0.5-0.1,1-0.1,1.4-0.2l0.3,0c0.5-0.1,0.7-0.1,0.8-0.3c0.1-0.1,0.1-0.3,0.2-0.4c-0.3-0.4-0.2-1,0.2-1.3s1-0.2,1.3,0.2s0.2,1-0.2,1.3
              c-0.1,0.1-0.2,0.1-0.4,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.3,0,0.6,0.1,0.9l0,0c0,0,0,0.1,0,0.1c0.5,0.1,0.9,0.5,0.8,1
              c-0.1,0.5-0.5,0.9-1,0.8c-0.5-0.1-0.9-0.5-0.8-1c0-0.2,0.1-0.3,0.2-0.4c0-0.1-0.1-0.2-0.1-0.2c-0.3-0.1-0.7-0.1-1,0l-0.3,0
              c-1.5,0.2-2.9,0.4-4.2,0.7c-0.5,0.1-1,0.3-1.4,0.5l0,0c-0.2,0.1-0.5,0.2-0.8,0.4c-0.6,0.3-1.1,0.5-1.4,0.6c-0.2,0.1-0.4,0.2-0.6,0.3
              c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0l-0.1,0.1l0,0l0.1-0.1C26.2,67.8,26.4,68,26.6,68.1z M40.4,67.7L40.4,67.7z M59.9,20.4
              c0.4,0.4,0.8,0.8,1.4,1.3c0.1,0.1,0.2,0.2,0.2,0.2c0.3,0.4,0.4,0.4,1.3,0.3l0.1,0c0.6-0.1,1.2-0.2,1.8-0.2c0.1,0,0.2,0,0.3,0
              c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0-0.3-0.2-0.6-0.4-0.8l-0.2-0.2c-0.5-0.8-1.3-1.4-2.1-1.8c-1.5-0.5-3.1-0.8-4.7-0.8c0,0-0.1,0-0.1,0
              c0.1,0.1,0.3,0.3,0.5,0.5C59,19.5,59.4,20,59.9,20.4z M71.4,26.6c-0.5,0.2-1,0-1.2-0.4c-0.2-0.5,0-1,0.4-1.2s1,0,1.2,0.4
              c0,0,0,0.1,0.1,0.1c0.2,0.1,0.3,0.2,0.5,0.2c0.8,0.3,1.5,0.7,2.1,1.2c-0.2-0.3-0.5-0.6-0.8-0.8c-0.6-0.4-1.2-0.9-1.8-1.3l-0.6-0.5
              c-1.6-1.3-3.4-2.5-5.3-3.4c0.5,0.6,0.9,1.2,1.2,1.8c0.2,0.3,0.4,0.6,0.5,0.8c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0.6,0.8,1.2,1.2,1.8
              c0.4,0.6,0.9,1.2,1.3,1.9c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.3,0.4,0.3c0.5,0.2,0.9,0.3,1.2,0.4c0.4-0.3,1-0.2,1.3,0.2
              c0.3,0.4,0.2,1-0.2,1.3c-0.4,0.3-1,0.2-1.3-0.2c0,0-0.1-0.1-0.1-0.1l-0.5-0.2c-0.2-0.1-0.5-0.1-0.7,0c-0.5,0.4-0.8,0.9-0.8,1.5
              c0,0.1,0,0.2,0.1,0.3l0.1,0.1c0.6,0.6,1,1,1.7,0.7c0.9-0.3,1.7-0.6,2.5-0.9c0.2-0.1,0.3-0.1,0.5-0.1c0.3,0,0.7,0.2,0.9,0.4
              c0.6,0.6,1.1,1.1,1.7,1.6c0.2,0.2,0.4,0.3,0.5,0.5c0.3-0.6,0.3-0.6-0.1-1.2c-0.3-0.4-0.6-0.8-0.9-1.2c-0.2-0.2-0.3-0.4-0.5-0.6
              c-1.1-1.4-2.5-2.6-4.1-3.5l0,0C72,26.9,71.7,26.8,71.4,26.6L71.4,26.6z M49.4,30.3C49.4,30.3,49.4,30.3,49.4,30.3
              c-0.2-0.2-0.4-0.5-0.6-0.8c-0.4-0.5-0.7-0.9-1.1-1.4c-0.3-0.2-0.5-0.5-0.7-0.8c0,0-0.1-0.1-0.1-0.1c-0.3,0.1-0.6,0.1-1,0.3
              c-0.2,0.1-0.4,0.1-0.6,0.2c0,0,0,0,0,0.1c-0.2,0.5-0.7,0.7-1.1,0.5c-0.2-0.1-0.4-0.3-0.5-0.5c-0.2-0.4,0-1,0.4-1.2
              c0.2-0.1,0.5-0.1,0.7,0c0,0,0.1,0,0.1,0c0.3-0.1,0.6-0.3,0.9-0.4c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.1c-0.6-0.8-1.1-1.7-1.6-2.5
              c-0.6-1-1.2-2-1.8-2.9c-0.4-0.5-0.9-0.9-1.6-1c-1.6-0.3-3.1,0.2-4.3,1.3c-0.2,0.1-0.3,0.3-0.5,0.4l1.4,1h0.1c0.5,0,0.9,0.4,0.9,0.9
              s-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2c-0.2-0.2-0.4-0.4-0.7-0.5c-0.7-0.6-1.8-0.7-2.5-0.1
              L33,22.8c-0.8,0.4-1.5,0.8-2.2,1.3c-0.2,0.2-0.5,0.3-0.8,0.5c-0.5,0.4-1,0.8-1.5,1.2c-0.1,0.1-0.3,0.2-0.4,0.3l-0.6,0.5
              c-0.4,0.3-0.5,0.6-0.4,0.9c0.2,0,0.4,0,0.5-0.1c0.1,0,0.2-0.1,0.3-0.1l0,0c0.1-0.5,0.6-0.7,1.1-0.6c0.5,0.1,0.7,0.6,0.6,1.1
              s-0.6,0.7-1.1,0.6c-0.1,0-0.2-0.1-0.3-0.2c-0.4,0.2-0.7,0.4-0.9,0.8c-0.1,0.2-0.2,0.5-0.4,0.7c-0.1,0.1-0.1,0.3-0.2,0.4
              c0.3,0.4,0.2,1-0.2,1.3c-0.4,0.3-1,0.2-1.3-0.2s-0.2-1,0.2-1.3c0.1-0.1,0.2-0.1,0.4-0.2l0-0.1c0.1-0.2,0.2-0.4,0.2-0.7
              c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-2.3,0.7-3.8,2.8-4.8,4.6c-0.2,0.2-0.2,0.6-0.1,0.8c0.2,0.2,0.4,0.4,0.7,0.3
              c0.3,0,0.5-0.2,0.6-0.4c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.2-0.4,0.3-0.6c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.5,0.4-0.9,0.9-0.9
              c0.5,0,0.9,0.4,0.9,0.9c0,0.4-0.3,0.8-0.7,0.9c0,0.1-0.1,0.2-0.1,0.3l0,0.1c-0.6,1.5-0.5,1.6,0.3,1.9c0.8,0.4,1.7,0.6,2.5,0.4
              c0.2,0,0.2-0.1,0.2-0.4l0-0.1c0.3-1.8,0.9-4.4,2.5-6c0.8-0.8,1.6-1.5,2.4-2.2l0.4-0.4l-0.3-0.6c-0.5-0.1-0.8-0.6-0.7-1.1
              c0.1-0.5,0.6-0.8,1.1-0.7c0.5,0.1,0.8,0.6,0.7,1.1c0,0.1,0,0.2-0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0.3,0.4,0.8,0.7,1.3,0.7l0.2,0
              c1.3,0.2,2.7,0.3,4,0.2l0.9,0c0.2,0,0.3,0,0.4,0c0.3,0,0.5,0,0.8-0.1c0-0.2,0-0.4-0.1-0.6l0-0.1c-0.1-0.2-0.1-0.3-0.1-0.4
              c-0.4-0.2-0.6-0.8-0.4-1.2c0.2-0.4,0.8-0.6,1.2-0.4s0.6,0.8,0.4,1.2c0,0.1-0.1,0.1-0.1,0.2c0.1,0.6,0.4,1.2,0.6,1.8
              c0.7,1.3,0.9,1.6,1.4,2.3l0.3,0.5c0.1,0.2,0.4,0.3,0.6,0.3c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.2,0c0.2-0.2,0.4-0.3,0.7-0.3
              c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9c-0.4,0-0.7-0.2-0.8-0.6c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.3-0.1-0.5-0.1
              c-0.4,0-0.8,0.3-0.9,0.7c0,0,0,0.1-0.1,0.1c0.3,0.4,0.2,1-0.2,1.3c-0.4,0.3-1,0.2-1.3-0.2c-0.3-0.4-0.2-1,0.2-1.3
              c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.2,0.2-0.3c-0.2-0.4-0.3-0.7-0.5-1.1c-0.2-0.4-0.4-0.8-0.5-1.2
              c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.1-0.3-0.5-0.3h-0.1c-1.3,0-2.5,0-3.8,0.2c-0.2,0.2-0.3,0.4-0.4,0.6c0.3,0.4,0.3,0.9-0.1,1.3
              c-0.4,0.3-0.9,0.3-1.3-0.1c-0.3-0.4-0.3-0.9,0.1-1.3c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.3,0.1-0.4c-0.3,0-0.7-0.1-1.3-0.2
              l-0.6-0.1c-0.5-0.1-0.5-0.1-0.7,0.1l-0.3,0.2c-1.6,1.1-2.9,2.6-3.8,4.4c-0.6,1.3-1,2.8-0.9,4.2c0.1,0.3,0.1,0.7,0.3,1
              c0.5,0.1,0.8,0.6,0.7,1s-0.6,0.8-1,0.7s-0.8-0.6-0.7-1c0-0.1,0.1-0.2,0.1-0.3l0-0.1c-0.1-0.2-0.1-0.4-0.2-0.5
              c-0.1-0.3-0.3-0.5-0.6-0.4c0,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.5-0.1c-0.2,0-0.4-0.1-0.6-0.1l0,0c-0.2-0.1-0.5-0.1-0.7,0
              c-0.1,0.4-0.2,0.7-0.1,1.1c0.2,0.2,0.4,0.4,0.4,0.7c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9c0-0.3,0.2-0.6,0.4-0.7
              c0-0.4,0-0.8,0-1.2l0-0.1c0-0.3-0.1-0.3-0.7-0.7c-0.5-0.4-1.2-0.7-1.8-0.6c-0.4,0.2-0.8,0.6-0.9,1.1c-0.3,0.8-0.6,1.6-0.7,2.4
              c-0.1,0.3-0.1,0.7,0,1c0.1,0.7,0.5,1.3,1.2,1.5c0-0.1,0.1-0.2,0.1-0.3l0.1-0.3c0,0,0,0,0,0c-0.3-0.4-0.3-1,0.1-1.3
              c0.4-0.3,1-0.3,1.3,0.1s0.3,1-0.1,1.3c-0.1,0.1-0.2,0.1-0.3,0.2l-0.1,0.2c0,0.2-0.1,0.3-0.1,0.5c0,0.2,0,0.3,0.2,0.3
              c0.6,0.1,1.3,0.2,1.9,0.2h0c0.5,0.1,1.1,0.1,1.7,0.2c0.2,0,0.3,0,0.5-0.1c0.7-0.4,1.5-0.8,2.2-1.1c0.8-0.4,1.6-0.8,2.4-1.2
              c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.1,0.5-0.3,0.6-0.6c0.1-0.4,0.2-1,0.3-1.6s0.2-1.2,0.3-1.7c-0.4-0.4-0.3-0.9,0-1.3
              c0.4-0.4,0.9-0.3,1.3,0c0.4,0.4,0.3,0.9,0,1.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.7-0.2,1.4-0.2,2.2v0c0,0.1,0,0.3,0,0.4
              c-0.1,0.6,0,1.2,0.4,1.6c0.1,0.1,0.2,0.3,0.3,0.5c0.9,1.4,1.9,2.8,3.2,3.9c0.3,0.3,0.7,0.6,1.1,0.7c1.3,0.4,2.6,0.7,3.9,1.1l0.1,0
              c0.4,0.1,0.9,0.2,1.3,0.3c0.5,0.1,1,0.3,1.6,0.4c1,0.3,1.9,0.6,2.9,0.8c0.2,0,0.3-0.1,0.4-0.5c0-0.1,0.1-0.2,0.1-0.3
              c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9c0,0.4-0.2,0.7-0.6,0.8c0,0.2-0.1,0.4-0.1,0.5l0,0.1
              c0,0.2-0.1,0.4-0.1,0.7c0.2,0.2,0.5,0.4,0.8,0.5c0.7,0.3,1,0.4,1.4,0.6c0.3,0.1,0.7,0.3,1.4,0.5c0.8,0.3,2,0.9,3.1,1.4
              c0.3,0.2,1.9,1,2.5,1.3c0.4,0.4,1.1,0.5,1.6,0.2c0.1,0,0.2-0.1,0.4-0.1v0c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9
              c0,0.5-0.4,0.9-0.9,0.9c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.3-0.4,0.3-0.3,0.7c0.1,0.2,0.2,0.5,0.3,0.7
              c0.1,0.2,0.1,0.3,0.2,0.5l0,0l0.4,0.3c0.3,0.4,0.3,0.9-0.1,1.3s-0.9,0.3-1.3-0.1C59.1,58,59,57.7,59,57.5c0-0.1,0-0.1,0-0.2l0.2-0.4
              l0,0c0-0.1-0.1-0.3-0.2-0.5c-0.1-0.4-0.4-0.7-0.7-0.8l0,0c-0.1-0.1-0.2-0.1-0.4-0.2c-1-0.6-2.6-1.4-3.1-1.6
              c-2.4-1.1-5.3-2.3-8.1-3.5c-0.2-0.1-0.4,0-0.5,0.1c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.4,0.4l-0.2,0.2l-0.1,0.1c0,0,0,0.1,0,0.1
              c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9c0.1,0,0.1,0,0.2,0c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.3,0.3-0.5
              c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.7-0.3-0.9-0.3c-1.4-0.4-2.3-0.7-3.6-1L39.9,48c-0.7-0.2-1.4-0.3-2.1-0.5l-0.2,0
              c-0.7-0.1-0.8,0-1.1,0.9c0,0.1-0.1,0.2-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.4,0,0.8,0.3,1c0,0,0,0.1,0.1,0.1l0.1,0.1l0.2,0.2
              c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9c0,0,0-0.1,0-0.1L35.8,51c-0.8-0.7-1.2-0.8-3.8-0.3
              c-0.2,0.4-0.8,0.6-1.2,0.4c-0.4-0.2-0.6-0.8-0.4-1.2c0.2-0.3,0.5-0.5,0.8-0.5c0.2,0,0.4,0.1,0.6,0.2c0.2-0.1,0.5-0.1,0.7-0.2
              c0.5-0.1,1-0.2,1.4-0.3c0.7-0.2,1.2-0.9,1.3-1.6c0.3-0.4,0.2-0.9-0.2-1.2c-0.2-0.2-0.3-0.4-0.4-0.5c-0.1-0.2-0.2-0.3-0.4-0.5
              c-0.3-0.4-0.6-0.8-0.9-1.2c-0.3-0.4-0.6-0.8-0.9-1.2c-0.5-0.6-1.4-0.9-2.2-0.6c-1.5,0.5-2.9,1.2-4.1,2.2l-0.1,0.1
              c-0.2,0.4-0.3,1,0,1.4c0,0.1,0,0.1,0,0.2c0.4,0.3,0.6,0.8,0.3,1.2s-0.8,0.6-1.2,0.3c-0.3-0.2-0.4-0.5-0.4-0.8c0-0.2,0.1-0.4,0.2-0.5
              l0-0.1c0-0.3-0.1-0.7-0.2-1c-0.1-0.5-0.1-0.5-0.3-0.5c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.4-0.1l-0.6-0.1
              c-0.2-0.1-0.4-0.1-0.6-0.1c-0.7-0.2-1.4-0.3-2.1-0.4c-0.4,0-0.8-0.2-1-0.5c-0.3-0.6-0.9-1.1-1.6-1.2c-0.2-0.1-0.2-0.1-0.4,0.2l0,0
              c-0.5,1.4-0.8,2.9-0.8,4.5v0.1c0,0.6,0,0.9,0.4,1c0.5,0.2,1.1,0.4,1.6,0.5c0.5,0.1,1,0.2,1.3,0.4l0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.5
              c-0.3-0.4-0.2-1,0.2-1.3s1-0.2,1.3,0.2c0.3,0.4,0.2,1-0.2,1.3c-0.1,0.1-0.2,0.1-0.4,0.2c-0.5,0.7-0.6,1.6-0.3,2.3l0,0.1
              c0,0.1,0.1,0.2,0.1,0.3c0.5,0.1,0.8,0.6,0.7,1.1c-0.1,0.5-0.6,0.8-1.1,0.7c-0.5-0.1-0.8-0.6-0.7-1.1c0-0.1,0.1-0.3,0.2-0.4
              c0-0.1-0.1-0.2-0.1-0.3c0-0.2-0.2-0.5-0.4-0.6l0,0c-0.4-0.1-0.8-0.1-1.2-0.2c-0.2,0-0.4,0-0.5-0.1c-0.1,0-0.2,0-0.3,0
              c-0.1,0.2-0.1,0.3-0.1,0.5l0,0.1c-0.2,2.2,0.2,4.4,1.1,6.5c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.2,0.3,0.1,0.6-0.2l0.1-0.1
              c0.1-0.2,0-0.4-0.1-0.6c0-0.1-0.1-0.2-0.1-0.2c-0.5-0.1-0.8-0.5-0.7-1c0.1-0.5,0.5-0.8,1-0.7c0.5,0.1,0.8,0.5,0.7,1
              c0,0.2-0.1,0.3-0.2,0.4c0.1,0.2,0.1,0.3,0.2,0.5c0.4,1.1,0.5,1.3,3.3,2.6c0.4-0.3,1-0.3,1.3,0.1c0.3,0.4,0.3,1-0.1,1.3
              c-0.4,0.3-1,0.3-1.3-0.1c-0.1-0.1-0.1-0.2-0.2-0.3l-0.1,0c-0.5-0.2-1-0.4-1.5-0.7c-0.3-0.1-0.5-0.3-0.8-0.4
              c-0.5-0.3-0.5-0.3-1.2,0.5c0,0,0,0,0,0c0.3,0.9,0.9,1.5,1.8,1.8c1.8,0.8,3.9,0.6,5.5-0.6c0.7-0.4,0.7-0.5,0.7-0.8v-0.1
              c0-1-0.3-2-0.8-2.9c-0.2-0.4-0.3-0.4-0.6-0.3c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.5-0.7,0.7-1.2,0.5s-0.7-0.7-0.5-1.2
              c0.2-0.5,0.7-0.7,1.2-0.5c0.1,0.1,0.2,0.1,0.3,0.2c0.4-0.1,0.7-0.2,1-0.3l0.2-0.1c0.3,0,0.5-0.2,0.6-0.4c0-0.3-0.1-0.7-0.3-0.9
              c-0.3-0.4-0.6-1-0.9-1.4h-0.1c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9c0,0,0,0,0,0c0,0.1,0,0.3-0.1,0.4
              c0.2,0.2,0.4,0.4,0.5,0.6l0.1,0.1c0.2,0.3,0.5,0.6,0.8,0.9l0.3,0.2c0.3,0.4,0.8,0.6,1.3,0.7c0.1-0.1,0.4-0.4,0.6-0.6
              c-0.2-0.5,0-1,0.4-1.2c0.5-0.2,1,0,1.2,0.4c0.2,0.5,0,1-0.4,1.2c-0.1,0.1-0.2,0.1-0.4,0.1h0c0,0-0.1,0.1-0.1,0.1
              c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.4-0.5,0.4-1.1,0.2l-0.1,0c-0.1-0.1-0.3-0.1-0.5-0.2
              c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.3-0.1,0.6,0,0.8c0.1,0.4,0.2,0.7,0.2,1l0,0.1c0.4,1.7,0.4,1.7,1.4,1.6
              c0.6,0,1.3-0.1,2.2-0.1c0.4,0,0.8,0,1.1,0c0.8,0,2,0.1,3.5,0.3c2.3,0.2,4.5,0.7,6.7,1.3c0.8,0.2,1.7,0.5,2.4,0.8l0,0l0.4,0.2
              c0.2,0.1,0.5,0.2,0.8,0.1l0.1,0c0.3-0.1,0.6-0.2,0.9-0.4l0.2-0.1c0.3-0.2,0.7-0.3,1.1-0.4c0.2-0.5,0.7-0.7,1.2-0.5
              c0.5,0.2,0.7,0.7,0.5,1.2c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4,0-0.6-0.2c-0.6,0.2-1.1,0.5-1.5,0.8l0.1,0.1
              c3.5,2.1,7.7,2.6,11.5,1.2c3-0.9,5-3.1,5.8-6.5c0.1-0.5,0.2-1.1,0.3-1.5c0.1-0.5,0.2-1.1,0.4-1.7c0.3-0.9,0-1.8-0.6-2.4
              c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.3-0.4-0.5-0.5c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1,0-0.2,0.1
              c0,0.1,0,0.2,0,0.3c0,0.3,0,0.5,0,0.7l0,0.1c0.4,0.3,0.5,0.9,0.2,1.3c-0.3,0.4-0.9,0.5-1.3,0.2c-0.2-0.2-0.4-0.4-0.4-0.7
              c0-0.3,0.1-0.6,0.4-0.7c0-0.2,0-0.4,0-0.6c0-0.2,0-0.5,0-0.7c0-0.4,0-0.8-0.2-1.2c-0.5-0.7-1-1.3-1.5-1.9c-0.1-0.1-0.2-0.3-0.4-0.4
              c-0.5-0.7-1.3-1.2-2.1-1.4c-0.3-0.1-0.7-0.2-1.3-0.4c-1.9-0.7-2-0.5-2.2,0l-0.1,0.2c-0.1,0.2-0.2,0.5-0.3,0.8l0,0.1
              c0.3,0.4,0.2,1-0.2,1.3s-1,0.2-1.3-0.2c-0.3-0.4-0.2-1,0.2-1.3c0.1-0.1,0.2-0.1,0.3-0.1c0-0.1,0.1-0.2,0.1-0.3
              c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0-0.2,0.1-0.3c0.1-0.4,0.1-0.4-1-1L52.9,44c-1.9-1.1-3.9-2-5.9-2.9c-1.7-0.7-3.4-1.2-5.2-1.4
              c-0.2,0-0.4-0.1-0.6-0.1c-0.5-0.2-1.2-0.1-1.6,0.3c-0.3,0.4-0.6,0.8-0.9,1.2c0,0.1,0,0.1,0,0.2c0,0.5-0.4,0.9-0.9,0.9
              c-0.5,0-0.9-0.4-0.9-0.9c0-0.4,0.3-0.8,0.7-0.9c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.3,0.3-0.6,0.5-0.9c0.2-0.3,0.1-0.4-0.1-0.7l-0.1-0.1
              c-0.3-0.4-0.6-0.9-0.9-1.4c0-0.1-0.1-0.1-0.1-0.2l0,0c-0.2-0.3-0.2-0.6-0.2-0.8c0-0.6,0.1-1.1,0.2-1.7l0-0.1c-0.4-0.3-0.4-0.9,0-1.3
              c0.3-0.4,0.9-0.4,1.3,0c0.4,0.3,0.4,0.9,0,1.3c-0.1,0.1-0.1,0.1-0.2,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.6,0,1.2,0.2,1.8
              c0.3,0.4,0.4,0.7,0.6,1c0.2,0.5,0.6,0.9,1.2,1c0.3,0.1,1.3,0.2,1.9,0.4c0.3,0.1,0.6,0.1,0.7,0.1l0.2,0c0.7,0.1,1.1,0.2,1.3-0.2
              c0.1-0.1,0.1-0.2,0.2-0.4s0.1-0.2,0.1-0.3c-0.3-0.4-0.2-1,0.2-1.3c0.4-0.3,1-0.2,1.3,0.2c0.3,0.4,0.2,1-0.2,1.3
              c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.2,0.1,0.3,0.5,0.5l0.2,0.1c0.8,0.4,1.5,0.7,2.2,1.1
              c0.6,0.3,1.2,0.6,1.8,0.9l0.7,0.4c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0c0.3-0.2,0.5-0.4,0.8-0.7
              c0.1-0.1,0.3-0.3,0.4-0.4v0c0-0.5,0.3-0.9,0.8-1c0.2,0,0.5,0.1,0.7,0.2c0.4,0.3,0.4,0.9,0.1,1.3c-0.2,0.2-0.4,0.3-0.6,0.3
              c0,0-0.1,0-0.1,0c-0.2,0.3-0.4,0.5-0.7,0.7c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1l0,0c0.2,0.1,0.4,0.2,0.7,0.3
              c1,0.4,2,0.8,3,1.2c0.6,0.2,1.2,0.3,1.8,0.4c0,0,0,0,0.1,0c0,0,0,0,0-0.1c0-0.3,0-0.6-0.1-0.9c0,0,0-0.1,0-0.1
              c-0.5-0.1-0.7-0.7-0.6-1.1s0.7-0.7,1.1-0.6c0.5,0.1,0.7,0.7,0.6,1.1c0,0.1-0.1,0.3-0.2,0.4c0,0.1,0,0.2,0.1,0.3s0.1,0.2,0.1,0.3
              c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.5,0.6,0.8,1.1,0.7c0.4-0.1,0.7-0.3,1-0.5c0.1-0.1,0.2-0.2,0.2-0.3c-0.1-0.1-0.1-0.3-0.1-0.4
              c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.8,0.9c-0.2,0.3-0.4,0.5-0.6,0.8c-0.3,0.2-0.4,0.7-0.2,1
              c0,0,0,0.1,0.1,0.1c0.4,0.6,0.8,1.1,1.3,1.6c0.3,0.4,0.8,0.7,1.3,0.8c0.2,0,0.5,0,0.6-0.1c0.1-0.1,0.2-0.1,0.4-0.2
              c0.3-0.2,0.6-0.3,0.8-0.4l0.1-0.1c0.1-0.5,0.6-0.8,1.1-0.7c0.5,0.1,0.8,0.6,0.7,1.1c-0.1,0.4-0.5,0.7-0.9,0.7c-0.1,0-0.2,0-0.3,0
              c-0.6,0.3-1,0.5-1.3,0.7c-0.1,0-0.1,0.1-0.1,0.1l0.5,0.6c0.4,0.5,0.9,1,1.3,1.4c0.2,0.2,0.5,0.3,0.8,0.3c0.3-0.1,0.6-0.1,0.9-0.1
              l0.3,0c0.1,0,0.3,0.1,0.3,0.2c0,0,0,0.1,0,0.1c-0.2,0.2-0.1,0.6,0.1,0.8c0,0,0,0,0,0c0.2,0.2,0.5,0.4,0.9,0.5
              c0.4,0.1,0.8,0.2,1.2,0.2c0.2,0,0.4,0,0.6,0.1c0.6,0.1,1.2-0.2,1.4-0.8c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.1,0.3-0.1
              c0.1,0,0.1,0.1,0.1,0.2l-0.1,0.7c2,0.4,4-0.7,4.6-2.6l0-0.1c0.1-0.1,0.1-0.2,0.1-0.4c0.1-0.5,0.1-1.1,0.1-1.6c0-0.1,0-0.2,0.1-0.3
              c0.1,0,0.1,0,0.2,0c0.2,0.1,0.3,0.3,0.3,0.4c0.2-0.4,0.3-0.8,0.4-1.2l0,0c0-0.2,0.1-0.3,0.1-0.4c0.2-1.2,0.2-2.5,0-3.7l0,0
              c0-0.3-0.1-0.6-0.1-0.9c-0.1-0.6-0.1-1.1-0.1-1.6s-0.1-0.9-0.1-1.4c-0.1-1.6-0.5-3.1-1.2-4.5c-0.1-0.3-0.3-0.5-0.6-0.7
              c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1.1c0,0.1,0,0.1,0,0.2l0,0.1c0,0.2,0,0.3,0.1,0.4
              c0.4,0.3,0.6,0.8,0.3,1.2s-0.8,0.6-1.2,0.3c-0.4-0.3-0.6-0.8-0.3-1.2c0-0.1,0.1-0.2,0.2-0.2c0-0.5-0.1-1-0.3-1.5
              c-0.3-0.5-0.7-0.9-1.1-1.2c-0.3-0.3-0.6-0.5-0.9-0.8c-0.5-0.6-1.2-0.8-1.9-0.5c-0.6,0.1-1.1,0.4-1.6,0.7c0,0.2,0.1,0.4,0.2,0.6
              c0.5,0.1,0.8,0.5,0.7,1c-0.1,0.5-0.5,0.8-1,0.7c-0.4-0.1-0.8-0.4-0.8-0.9c0-0.1,0-0.3,0.1-0.4c-0.1-0.1-0.2-0.3-0.2-0.4
              c-0.1-0.2-0.2-0.4-0.3-0.5c-1.3-1-2.8-1.6-4.4-1.8c-0.5-0.1-0.5,0-0.6,0.1l-0.1,0.4c-0.1,0.3-0.1,0.5-0.2,0.7c0.3,0.4,0.2,1-0.3,1.2
              c-0.4,0.3-1,0.2-1.2-0.3c-0.3-0.4-0.2-1,0.3-1.2c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2,0.1-0.3l0.1-0.3c0.1-0.4,0.1-0.8,0.1-1.3
              c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.1-0.1-0.2-0.1-0.2s-0.1-0.1-0.1-0.2c-0.5-0.1-0.8-0.6-0.6-1.1c0.1-0.5,0.6-0.8,1.1-0.6
              c0.4,0.1,0.7,0.5,0.7,0.9c0,0.2,0,0.3-0.1,0.5c0.2,0.3,0.3,0.7,0.4,1c0,0,0,0,0,0c0.5,0.2,1.1,0.4,1.7,0.4c0.2,0,0.5,0,0.8,0.1
              c0.4,0,0.4,0,0.5-0.3c0.3-0.5,0.5-1,0.7-1.5c0.1-0.2,0.1-0.6,0-0.8l0-0.1c-0.4-0.7-0.8-1.4-1.3-2l-0.2-0.3c-0.1-0.1-0.1-0.1-0.6-0.1
              h-0.1c-0.1,0-0.3,0-0.4,0h0c-0.2,0-0.3,0-0.5,0c-0.3,0.4-0.9,0.4-1.3,0.1c-0.4-0.3-0.4-0.9-0.1-1.3c0.3-0.4,0.9-0.4,1.3-0.1
              c0.1,0.1,0.1,0.1,0.2,0.2l0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0l0-0.1c-0.2-0.5-0.5-0.9-0.9-1.3c-0.4-0.2-0.8-0.2-1.1-0.1
              c-0.2,0-0.4,0.1-0.7,0.1l-0.3,0c-1,0.1-1.3,0.1-2.2,1.6c-0.4,0.7-0.7,1.4-1.1,2.1c-0.2,0.5-0.5,1-0.7,1.5c-0.1,0.3-0.2,0.5-0.3,0.8
              c-0.2,1.5-0.3,3-0.3,4.5c0,0.2,0.1,0.4,0.3,0.5c0.3,0.1,0.9,0.3,1.3,0.5l0.1,0c1,0.4,1.8,0.7,2.6,1.1l0,0c1.2,0.5,2.4,1,3.6,1.4
              c0.6,0.2,1.2,0.2,1.8,0c0.3-0.1,0.5-0.1,0.8-0.2c0.2-0.5,0.7-0.7,1.2-0.5c0.5,0.2,0.7,0.7,0.5,1.2c-0.2,0.5-0.7,0.7-1.2,0.5
              c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.6,0.2c-0.6,0.2-0.7,0.3-0.8,0.7c-0.1,1.3,0,2.6,0.5,3.8c0.1,0.1,0.1,0.2,0.3,0.3
              c0.6,0.2,1.3,0.4,1.9,0.6l0.4,0.1c0.2,0.1,0.2,0,0.4-0.1c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.4-0.5,0.6-0.8l0,0c0.1-0.2,0.2-0.4,0.3-0.5
              c0-0.1,0-0.1,0-0.2c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.3,0.8-0.8,0.9c-0.1,0.1-0.2,0.2-0.3,0.4
              c-0.2,0.3-0.4,0.5-0.6,0.8L73.3,44c-0.3,0.4-0.5,0.9-0.7,1.4c-0.1,0.2-0.1,0.5-0.2,0.7c0,0.1,0,0.1,0,0.2c0.3,0.4,0.3,0.9,0,1.3
              s-0.9,0.3-1.3,0c-0.3-0.4-0.3-0.9,0-1.3c0.1-0.1,0.2-0.1,0.3-0.2c0-0.3,0.1-0.6,0.1-0.9c0-0.3-0.1-0.4-0.6-0.5l-0.1,0
              c-0.5-0.2-1.1-0.3-1.6-0.4L69,44.3c-0.4-0.2-0.8-0.1-1.2,0c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.5-0.6,0.8-1.1,0.6
              c-0.5-0.1-0.8-0.6-0.6-1.1c0.1-0.5,0.6-0.7,1-0.7c0.4-0.2,0.7-0.4,1-0.7c0.2-0.4,0.2-0.8,0.1-1.3c0-0.2,0-0.3-0.1-0.5
              c-0.1-0.4-0.1-0.8-0.2-1.2l0-0.1c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.6-0.6-1-1.2-1.1c-2.4-1.1-4.9-2-7.5-2.8c-0.3-0.1-0.6,0-0.7,0.3
              c0,0,0,0,0,0.1c-0.1,0.2-0.2,0.3-0.2,0.4s-0.1,0.2-0.2,0.4c-0.2,0.4-0.2,0.4,0.1,0.7c0.2,0.3,0.4,0.5,0.5,0.7c0.5,0.1,0.8,0.5,0.8,1
              c-0.1,0.5-0.5,0.8-1,0.8c-0.4-0.1-0.8-0.4-0.8-0.9c0-0.1,0-0.1,0-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5-0.6-2.2-0.6-2.7-0.6l-0.2,0
              c-0.3,0-0.5,0-0.6,0c-0.2,0.2-0.4,0.2-0.6,0.2c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9c0.2,0,0.4,0.1,0.6,0.2c0.2,0,0.5,0,1,0
              c0.1,0,0.3,0,0.5,0c0.5,0.1,1,0,1.4-0.2c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.2,0.2-0.3c1.1-1.8,1.5-4,1.2-6.1
              c0-0.5-0.1-0.6-0.8-1c-0.6-0.3-1.2-0.6-1.8-0.8c-0.1,0.1-0.2,0.3-0.3,0.4c0.3,0.4,0.2,1-0.2,1.3c-0.4,0.3-1,0.2-1.3-0.2
              c-0.3-0.4-0.2-1,0.2-1.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1-0.3,0.1-0.5,0.1-0.8c-0.2-0.8-0.5-1.6-1.1-2.2c-0.1-0.2-0.2-0.3-0.3-0.5
              c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.6-0.7-0.9-1.2-0.9c-0.1,0-0.3,0-0.5,0s-0.4,0-0.6,0c0,0,0,0,0,0.1c-0.3,0.4-0.9,0.4-1.3,0
              s-0.4-0.9,0-1.3c0.3-0.4,0.9-0.4,1.3,0c0,0,0,0,0.1,0.1c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0,0.7,0c0.3,0,0.3-0.1,0.4-0.5
              c0,0,0-0.1,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9
              c0,0.3-0.2,0.7-0.5,0.8c0,0.1-0.1,0.3-0.1,0.5c-0.1,0.5,0,0.9,0.2,1.3c0.9,1.4,1.6,2.5,2.3,3.6c0.1,0.2,0.4,0.4,0.6,0.5l0.1,0
              c0.5,0.2,1.1,0.4,1.6,0.6l0.2,0.1c0.5,0.2,0.5,0.2,0.9-0.3c0.4-0.7,0.8-1.4,1.1-2.1l0.1-0.2c0.3-0.3,0.3-0.7,0.2-1.1
              c-0.6-0.7-0.9-1-1.3-1.4c-0.3-0.3-0.6-0.6-1.2-1.2l0,0c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1,0-0.1,0c-0.3,0.1-0.6,0.3-0.9,0.4
              c-0.2,0.5-0.7,0.7-1.1,0.6c-0.5-0.2-0.7-0.7-0.6-1.1c0.1-0.4,0.5-0.6,0.8-0.6c0.2,0,0.4,0.1,0.6,0.2l0.2-0.1l0.2-0.1
              c0.1,0,0.2-0.1,0.3-0.1c-0.5-0.6-1.1-1-1.9-1.2c-0.6-0.1-1.2-0.3-1.7-0.4c-0.5-0.1-1-0.3-1.5-0.4c-1.7-0.3-3.5-0.1-5.2,0.6l-0.3,0.1
              c-0.9,0.2-1.7,0.7-2.2,1.5c-0.2,0.6-0.1,1.3,0.4,1.8c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.2,0.4-0.3,0.7-0.5c0.3-0.2,0.5-0.3,0.8-0.4
              c0.1-0.5,0.5-0.8,1-0.8c0.5,0.1,0.8,0.5,0.8,1c-0.1,0.4-0.4,0.8-0.9,0.8c-0.1,0-0.2,0-0.3-0.1c-0.1,0.1-0.2,0.2-0.4,0.3
              c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.3,0.3-0.3,0.3c-0.4,0.5-0.4,0.5-0.1,1.1c0.6,1.2,1.4,2.2,2.3,3.1l0.2,0.2
              c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.2,0.1,0.4,0.3l0,0c0.4,0.4,0.5,0.4,0.9,0.3c0.1,0,0.2-0.1,0.4-0.1c0.2,0,0.3-0.1,0.4-0.1
              c0.2-0.5,0.7-0.7,1.2-0.5s0.7,0.7,0.5,1.2c-0.1,0.4-0.5,0.6-0.8,0.6c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.2,0.1-0.3,0.1
              c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0-0.1,0-0.2,0.1c0.2,0.8,1.1,1.4,2.1,2.1c0.4,0.2,0.7,0.5,1.1,0.8c0.5,0.1,0.8,0.6,0.6,1.1
              s-0.6,0.8-1.1,0.6c-0.4-0.1-0.7-0.5-0.7-0.9v0c-0.2-0.3-0.6-0.6-0.9-0.9c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.4-0.1
              c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c0,0.1,0,0.1,0,0.2
              c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.8-0.9c0.3-0.3,0.5-0.5,0.8-0.8l0.1,0.1l0,0l-0.1-0.1
              C49.5,30.6,49.5,30.5,49.4,30.3L49.4,30.3z M58,59.6c-0.1,0.2-0.3,0.4-0.5,0.4l0,0c-0.2,0-0.3,0.1-0.5,0c-0.1,0.1-0.1,0.1-0.2,0.2
              c-0.2,0.2-0.3,0.4-0.4,0.6c0,0.2-0.1,0.5-0.1,0.7c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.5-0.1,1-0.6,1.1c-0.1,0-0.2,0-0.3,0
              c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.6-0.8-0.4-1.2c0.1-0.1,0.1-0.2,0.2-0.3c0-0.2-0.1-0.4-0.1-0.6c-0.2-0.3-0.5-0.6-0.9-0.7l-0.9-0.5
              c-0.9-0.5-1.8-1-2.8-1.6c-0.2-0.1-0.4-0.2-0.7-0.2c-0.2,0-0.3,0-0.6-0.1c-1.2-0.2-2.5-0.6-3.7-0.9l-0.1,0c0,0-0.1,0-0.1,0
              c-0.2,0-0.3,0-0.5,0.1l-0.1,0c-0.3,0.2-0.6,0.4-0.8,0.6L43.9,58c0,0.4-0.3,0.7-0.6,0.8c-0.2,0.1-0.5,0-0.7-0.1
              c-0.5-0.2-0.7-0.7-0.5-1.2c0.2-0.5,0.7-0.7,1.2-0.5c0.3-0.2,0.5-0.4,0.7-0.7l0,0c-0.8-0.3-1.6-0.6-2.3-0.9l-0.1-0.1
              c-0.9-0.4-0.9-0.4-1.3-0.2L40,55.5c0,0.4-0.3,0.7-0.6,0.8c-0.2,0.1-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.3-0.4-0.5c-0.1-0.5,0.1-1,0.6-1.1
              c0.3-0.1,0.5,0,0.7,0.1c0.1-0.1,0.2-0.1,0.2-0.1c0.3-0.3,0.3-0.4,0.1-1c-0.1-0.2-0.1-0.3-0.1-0.4c-0.5-0.2-0.7-0.7-0.5-1.2
              c0.2-0.5,0.7-0.7,1.2-0.5c0.5,0.2,0.7,0.7,0.5,1.2c0,0.1-0.1,0.2-0.2,0.3c0,0.1,0.1,0.2,0.1,0.3c0.3,1,0.3,1,0.6,1.1l0.1,0.1
              c2.7,1.1,5.5,2,8.3,2.5c0,0,0,0,0,0c0-0.2,0-0.4-0.1-0.5c-0.4-0.2-0.6-0.8-0.4-1.2c0.1-0.2,0.3-0.4,0.5-0.4c0.2-0.1,0.5,0,0.7,0.1
              c0.4,0.2,0.6,0.8,0.4,1.2c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0,0,0c0,0.5,0.3,0.9,0.7,1c1,0.6,2.1,1.2,3.5,2.1c0.1,0.1,0.2,0.1,0.3,0.2
              c0.2,0.1,0.4,0.2,0.6,0.3c0.2-0.2,0.3-0.3,0.4-0.4c-0.1-0.5,0.2-1,0.6-1.1s1,0.2,1.1,0.6C58.2,59.2,58.1,59.4,58,59.6L58,59.6z
              M72.9,49.9c0.1-0.2,0.3-0.4,0.5-0.4c0,0,0.1,0,0.1,0l0.2-0.3c1.3-1.5,2.2-3.4,2.5-5.4c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.3,0,0.5,0
              s0.4,0,0.5,0h0c0.1-0.2,0.3-0.3,0.5-0.4c0.5-0.1,1,0.1,1.1,0.6s-0.1,1-0.6,1.1l0,0c-0.4,0.1-0.7,0-1-0.3c-0.1,0-0.2,0-0.2,0
              c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c-0.4,2-1.3,3.8-2.7,5.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.5-0.1,1-0.6,1.1
              c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4-0.1C72.8,50.9,72.7,50.3,72.9,49.9L72.9,49.9z M74.7,53.2L74.7,53.2z M20.1,42.1L20.1,42.1z
              M74.8,53.9L74.8,53.9z M164.4,29.9c-1.7-1.3-3.7-2.1-5.9-2.5c-3.6-0.6-7.3-0.8-11-0.7H132c-3.7-0.1-7.4,0.2-11,0.7
              c-2.1,0.4-4.1,1.2-5.9,2.5c-1.7,1.4-2.9,3.2-3.6,5.4c-0.8,3-1.2,6.1-1.1,9.2V57c-0.1,2.9,0.3,5.7,1.1,8.5c0.7,2,1.9,3.8,3.6,5.2
              c1.7,1.3,3.7,2.1,5.8,2.5c3.6,0.6,7.3,0.8,11,0.7h15.5c3.7,0.1,7.4-0.1,11.1-0.7c2.1-0.4,4.1-1.2,5.8-2.5c1.7-1.4,2.9-3.2,3.5-5.2
              c0.8-2.8,1.2-5.8,1.1-8.7V43.9c0.1-2.9-0.3-5.9-1.1-8.7C167.3,33.1,166.1,31.3,164.4,29.9L164.4,29.9z M162.8,55.4v1.6
              c0.1,1.8-0.1,3.6-0.4,5.3c-0.2,1.1-0.7,2.1-1.3,3c-1,1.2-2.3,2.1-3.8,2.6c-2.3,0.6-4.6,0.9-6.9,0.8h-21c-2.3,0.1-4.7-0.2-6.9-0.8
              c-1.5-0.4-2.8-1.3-3.8-2.6c-0.7-0.9-1.2-2-1.4-3.1c-0.3-2-0.5-3.9-0.4-5.9V44.1c-0.1-2,0.1-4,0.4-5.9c0.2-1.1,0.7-2.2,1.4-3.1
              c1-1.2,2.3-2.1,3.8-2.6c2.3-0.6,4.6-0.9,6.9-0.8h21c2.3-0.1,4.7,0.2,6.9,0.8c1.5,0.4,2.8,1.3,3.8,2.6c0.6,0.9,1.1,1.9,1.3,2.9
              c0.3,1.8,0.5,3.6,0.4,5.4L162.8,55.4z M213.3,38.6c-1.9-1.2-5.7-1.9-11.5-1.9h-25.4v36.6h4.5V58.9h20.9c5.8,0,9.7-0.6,11.5-1.8
              c1.8-1.2,2.8-3.5,2.8-6.8v-4.8C216.1,42.1,215.1,39.9,213.3,38.6L213.3,38.6z M209.6,53.6c-1.1,0.8-3.2,1.2-6.5,1.2h-22.2v-14h22.2
              c3.3,0,5.5,0.4,6.5,1.2s1.6,2.4,1.6,5l0,1.7C211.2,51.1,210.7,52.8,209.6,53.6L209.6,53.6z M222.8,73.3h36v-4.1h-31.5V56.4h30.2
              v-4.1h-30.2V40.8h31.5v-4.1h-36L222.8,73.3L222.8,73.3z M304.2,69.1l-32.4-32.4h-7v36.6h4.5V40.4l32.9,32.9h6.5V36.8h-4.4
              L304.2,69.1L304.2,69.1z M359,48.4c2.9-0.6,4.8-1.6,5.9-3c1.1-1.4,1.7-3.8,1.7-7.1c0.1-2.2-0.2-4.3-0.9-6.4c-0.6-1.5-1.7-2.7-3-3.5
              c-1.5-0.9-3.1-1.4-4.7-1.6c-3-0.4-6.1-0.5-9.1-0.5h-32.9v47.4h31.8c8.3,0,13.8-0.8,16.5-2.4s4-4.5,4-8.7v-5c0-3.1-0.7-5.3-2.1-6.8
              S362.3,48.6,359,48.4z M321.7,31.4h27.1c2.4,0,4.8,0,7.2,0.3c1.1,0.1,2.2,0.5,3.2,1c0.7,0.5,1.3,1.2,1.5,2.1
              c0.3,1.3,0.5,2.7,0.4,4.1c0,2.9-0.7,4.9-2,5.8s-5,1.4-11.2,1.4h-26.2V31.4L321.7,31.4z M360.5,66.8L360.5,66.8
              c-1.4,1-4.3,1.5-8.8,1.5h-30.1V51.7h30.1c4.6,0,7.5,0.5,8.8,1.6c1.3,1.1,2,3.5,2,7.2C362.6,63.7,361.9,65.8,360.5,66.8L360.5,66.8z
              M428.9,57.5h-5.3v1.3c0,3.8-0.8,6.4-2.4,7.9s-4.6,2.2-8.9,2.2h-19.9c-2.1,0.1-4.3-0.2-6.4-0.8c-1.4-0.5-2.7-1.4-3.5-2.6
              c-0.6-0.9-1.1-2-1.3-3.1c-0.3-2-0.4-4-0.4-6.1V43.6c-0.1-2,0.1-4.1,0.4-6.1c0.2-1.1,0.6-2.2,1.3-3.1c0.9-1.2,2.1-2.2,3.5-2.6
              c2.1-0.6,4.2-0.9,6.4-0.8h19.9c4,0,6.8,0.7,8.3,2.2c1.6,1.5,2.4,4.1,2.4,7.8v0.7h5.3c0-0.3,0-0.6,0-1s0-0.9,0-1.5
              c0-5.1-1.3-8.6-4-10.5s-7.7-2.9-15.2-2.9h-14.5c-3.4-0.1-6.8,0.2-10.2,0.7c-2,0.4-3.9,1.3-5.4,2.6c-1.6,1.5-2.8,3.4-3.3,5.5
              c-0.8,3.1-1.1,6.2-1,9.4v12.9c-0.1,3,0.3,5.9,1,8.8c0.6,2,1.7,3.9,3.3,5.3c1.5,1.3,3.4,2.2,5.3,2.6c3.4,0.6,6.9,0.8,10.3,0.7h14.4
              c3.6,0.1,7.2-0.1,10.8-0.7c1.9-0.3,3.7-1.1,5.2-2.3c1.3-1.2,2.3-2.7,2.8-4.3c0.6-2.3,0.9-4.7,0.9-7.1c0-0.2,0-0.6,0-1.1
              S428.9,57.8,428.9,57.5L428.9,57.5z M436.3,26.3v47.4h5.7V26.3L436.3,26.3L436.3,26.3z"/>
            </svg>
        </Link>
        <nav className='footer-links'>
          <a className='footer-link' href='https://shop.openbci.com/collections/frontpage '>Shop</a>
          <Link className={`footer-link ${location.pathname === "/careers" ? 'active' : ''}${location.pathname === "/jobs" ? 'active' : ''}`} to='/jobs' >Jobs</Link>
          <a className='footer-link' href="https://docs.openbci.com/">Docs</a>
          <Link className={`footer-link ${location.pathname === "/about" ? 'active' : ''}`} to='/about' >About</Link>
          <Link className={`footer-link ${location.pathname === "/donate" ? 'active' : ''}`} to='/donate' >Donate</Link>
          <Link className={`footer-link ${location.pathname === "/downloads" ? 'active' : ''}`} to='/downloads' >Downloads</Link>
          <Link className={`footer-link ${location.pathname === "/newsletter" ? 'active' : ''}`} to='/newsletter' >Newsletter</Link>
          <a className='footer-link' target='_blank' rel="noreferrer" href='mailto:contact@openbci.com?subject=General Inquiry' >Contact</a>
          <Link className={`footer-link ${location.pathname === "/citations" ? 'active' : ''}`} to='/citations' >Research & Citations</Link>
          <a className='footer-link' href='https://docs.openbci.com/FAQ/FAQLanding/' >FAQ</a>
        </nav>
        <div className="footer-socials">
        <a href="https://www.linkedin.com/company/openbci/">Li</a>
          <p>/</p>
          <a href="https://www.facebook.com/OpenBCI/">Fb</a>
          <p>/</p>
          <a href="https://twitter.com/OpenBCI">Tw</a>
          <p>/</p>
          <a href="https://www.youtube.com/channel/UC3fzPmzq0XND2El9jIs802g">Yt</a>
        </div>
      </div>
      <div className="sub-footer">
        <p>© 2024 OpenBCI All Rights Reserved.</p>
        <Link className={`sub-footer-link ${location.pathname === "/copyright" ? 'active' : ''}`} to='/copyright'>Copyright Notice</Link>
        <a href='https://docs.openbci.com/FAQ/Privacy/'>Privacy Policy</a>
      </div>
    </React.Fragment>
  );
}
