import React from 'react';
import { BodyContent } from 'components/Sanity';

// Style
import './index.scss'

export const DownloadsSection = ({title, version, files, content}) => {

  return (
    <div className={`downloads-section`} >
      <div className="downloads-title-bar">
        <h5>{title}{version && <span>v{version}</span>}</h5>
      </div>
        <div className='downloads-section-content'>
          <BodyContent 
          className="downloads-section-copy"
          blocks = {content}
          />
          <div className="downloadable-files" >
            {files.map(({name, lastUpdate, icon, fileLinks}, i) =>
            <div className="downloadable-file" key={i}>
              <img src={icon} alt="Download format" className="downloadable-file-icon" />
              <h6>{name}</h6>
              <p>Last updated: </p>
              <p>{lastUpdate}</p>
              <div className='download-button-group'>
                {fileLinks && fileLinks.map(({link, href, download}, i) =>
                  <a href={href} className="button" key={i} download={download}>
                    <span>{link}
                      <svg version="1.1" id={`download-arrow-${download ? 'down' : 'up'}`} className='download-arrow' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 10 10" xmlSpace="preserve">
                        <polygon points="10,2 10,10 2,10 2,8.5 7.5,8.5 0,1 1,0 8.5,7.5 8.5,2 "/>
                      </svg>
                    </span>           
                  </a>
                )}
                </div>
              </div>
            )}
          </div>
        </div>
    </div>
  )
}