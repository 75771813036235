export function handleSubmit(stripe, price) {
  if (!stripe) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return;
  }
  if (stripe) {
    stripe.redirectToCheckout({
      mode: 'payment',
      lineItems: [{ price: price, quantity: 1 }],
      successUrl:
      'https://openbci.com/donate/#success',
      cancelUrl:
      'https://openbci.com/donate/#cancel'
    });
  }
}