import React from 'react';
import { BodyContent } from 'components/Sanity';

// Style
import './index.scss'

export const ShopFeature = ({ shopFeature, shopFeaturette }) => {

  return (
        <section className='shop-feature' >
            <a className='shop-feature-img-container' href={shopFeature.link.url}>
              <img className='shop-feature-img' src={ shopFeature.image } alt='product iamge'/>
            </a>
            <div className='shop-feature-text'>
              <h2><a href={shopFeature.link.url}>{shopFeature.title}</a></h2>
              <div className='rule-orange' />
              <p>OpenBCI headsets, boards, sensors and electrodes allow anyone interested in biosensing, neurofeedback, and brain computer interfacing to purchase high quality equipment at affordable prices.</p>
              <a className='text-arrow-link' href ={shopFeature.link.url}>{shopFeature.link.text}<span>{`❯`}</span></a>
            </div>
            {shopFeaturette.map(({title, body, image, link}, i)=>
              <div className='shop-featurette' key={i}>
                  <a className='shop-featurette-img-container' href={link.url}>
                    <img src={`${image}?fit=crop&w=513&h=375`} className='shop-featurette-img' alt='product category'/>
                  </a>
                  <h2><a href={link.url}>{title}</a></h2>
                  <div className='rule-orange' />
                  <BodyContent blocks={body} className='shop-featurette-body'/>
                  <a className='text-arrow-link' href={link.url}>{link.text}<span>{`❯`}</span></a>
              </div>
            )}
        </section>
  )
}