// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect, useState } from 'react';
import Slider from "react-slick";
import { useWindowSize } from 'react-use'
import gsap from 'gsap'

// Style
import './index.scss'
import { Link } from 'react-router-dom';

export const CitationsCarousel = ({citations}) => {

  let { width } = useWindowSize();
  const  [numberOfCitations, setNumberOfCitations] = useState(3)

  useEffect(()=> {
    gsap.to("#citation-counter", {
      textContent: 239,
      duration: 4,
      ease: "Power1.easeIn",
      snap: { textContent: 1 },
      stagger: 1,
      // add the below code if citations goes above 1000
      // onUpdate: textContent.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      scrollTrigger: {
        trigger: 'section.citations',
        start: "top bottom", // when the  of the trigger hits the  of the viewport
      }
    });
  }, [])

  useEffect(()=> {
    if (width < 900) {
      setNumberOfCitations(1);
      } else if (900 < width && width < 1400) {
      setNumberOfCitations (2);
    } else {
      setNumberOfCitations(3);
    } 
    }, [width, numberOfCitations])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: numberOfCitations,
    slidesToScroll: 1,
    arrows: width > 700,
    autoplay: width < 700
  };

  return (
        <section className='citations' >
          <h2>Cited in <span><div id='citation-counter'>0</div> projects</span> and counting...</h2>
          <Slider className='citations-list' {...settings}>
            {citations.map(({journal, authors, title, link}, i)=> 
              <a className='citation' href={link} key={i}>
                {journal && <div className='eyebrow'>{journal}</div>}
                {title && <h3>{title}</h3>}
                <div className='rule-orange' />
                {authors && <p>{authors}</p>}
              </a>
            )}
          </Slider>
          <Link to="/citations" className='text-arrow-link'>See all citations<span>❯</span></Link>
        </section>
  )
}