import React, { useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { BodyContent } from 'components/Sanity/index'
import { ScrollToTop } from 'utils/ScrollToTop';

// Queries
import { CaseStudiesContext } from 'contexts/CaseStudiesContext';

// Sections
import { Subscribe } from 'components/Subscribe'
import { Loader } from 'components/Loader';
import { PageHero } from 'components/PageHero';
import { PageOverhang } from 'components/PageOverhang';

// Style
import './index.scss'

export const CaseStudyPage = () => {
  const { caseStudies, loaded} = useContext(CaseStudiesContext);
  let { slug: urlSlug } = useParams()
  let navigate = useNavigate();
  
  const pageSlugs = caseStudies.map((caseStudy) => { return caseStudy.slug })

  const slugMatch = pageSlugs.includes(urlSlug)
  
    const caseStudy = caseStudies.find(({ slug }) => {
      return slug === urlSlug })

  //check to see if the slug exists in our project, otherwise send us home
  useEffect(() => {
    if (loaded){
      if (slugMatch) {
        (document.title = `OpenBCI Case Study | ${caseStudy.hero.heroEyebrow}`)
      } else {
        navigate("/")
      }
    }
  }, [loaded, navigate, slugMatch, caseStudy])
  

  

  return ( !loaded ?
    <Loader /> 
    :
    <div className={`case-study-page`} >
      <ScrollToTop />
      {caseStudy.hero && <PageHero 
        eyebrow={caseStudy.hero.heroEyebrow} 
        header={caseStudy.hero.heroHeader}
        image={caseStudy.hero.heroImg}
        hasButton={caseStudy.hero.hasButton}
        button={caseStudy.hero.button}
        tall={false}
      />
      }
      <section className="page-content-wrapper">
        {caseStudy.pageOverhang &&
          <PageOverhang 
            title={caseStudy.pageOverhang.title}
            author={caseStudy.author}
            date={caseStudy.creationDate}
            body={caseStudy.pageOverhang.body}
            sidenote={caseStudy.pageOverhang.sidenote}
          />
        }
        <BodyContent blocks={caseStudy.article} />
        </section>
      <Subscribe />
    </div>
  )
}