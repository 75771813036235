import React from 'react'

// Components

// Style
import './index.scss'


// A single block to account for all custom components
export const BlockImage = ({ node }) => {
  return (
    <div className={`blockImage-contianer blockImage-container-${node.type}`}>
      <img src={node.image} className={`blockImage`} alt={node.alt} />
    </div>
  )
}