import React, { useEffect, useState } from 'react';
import { ScrollToTop } from 'utils/ScrollToTop';

// Utils
import { getData } from 'utils/sanity/'

// Queries
import { downloadsQuery } from "utils/sanity/sanity-queries"

// Sections
import { PageHero } from 'components/PageHero'
import { Subscribe } from 'components/Subscribe'
import { DownloadsSection } from './DownloadsSection';
import { Loader } from 'components/Loader';

// Style
import './index.scss'
import { PageOverhang } from 'components/PageOverhang';

export const Downloads = () => {

  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [guiData, setGuiData]=useState([]);

  // Get the data from Sanity
  useEffect(() => {
    //Get data from CMS
    async function fetchData() { 
      try {
        const dataResponse = await getData(downloadsQuery)
        setData(dataResponse)
        setLoaded(true)
        getGuiData(dataResponse.downloadsSections)
        document.title = `OpenBCI | Downloads`
      }
      catch (err) {
        console.log('fetch failed', err);
        throw err
      }
    }
    //Get data from Github json for GUI
    const guiUrl = process.env.NODE_ENV === 'development' ? 'https://api.github.com/repos/OpenBCI/OpenBCI_GUI/releases/latest' : './LatestGuiReleaseFromGithub.json'
    const getGuiData=(myData)=>{
      fetch(guiUrl 
      ,{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
      )
      .then(function(response){
        //console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        var tempData = myData;
        if (tempData && tempData.length) {
          tempData = tempData.slice(0,1).shift();
          tempData.version = myJson.name.substring(1);
          //const date = new Date(myJson.published_at);
          //const simpleDate = date.getFullYear()+'-'+ (date.getMonth()+1)+'-'+date.getDate();
          var macLink;
          var macLinkDate;
          var winLink;
          var winLinkDate;
          var linuxLink;
          var linuxLinkDate;
          myJson.assets.forEach(element => {
            if (element.browser_download_url.endsWith("macosx.dmg")) {
              macLink = element.browser_download_url;
              macLinkDate = new Date(element.updated_at);
            } else if (element.browser_download_url.endsWith("windows64.zip")) {
              winLink = element.browser_download_url;
              winLinkDate = new Date(element.updated_at);
            } else if (element.browser_download_url.endsWith("linux64.zip")) {
              linuxLink = element.browser_download_url;
              linuxLinkDate = new Date(element.updated_at);
            }
          });
          tempData.files.forEach(element => {
            var date;
            if (element.name === "Mac") {
              element.fileLinks[0].href = macLink;
              date = macLinkDate;
            } else if (element.name === "Windows") {
              element.fileLinks[0].href = winLink;
              date = winLinkDate;
            } else if (element.name === "Linux") {
              element.fileLinks[0].href = linuxLink;
              date = linuxLinkDate;
            }
            element.lastUpdate = date.getFullYear()+'-'+ (date.getMonth()+1)+'-'+date.getDate();
          });
          setGuiData(tempData);
        }
      });
    }
    fetchData()
  }, []);

  // Set the page title once
  useEffect(() => {
    document.title = "OpenBCI | Downloads"
  }, [])

  return (!loaded ?
    <Loader /> 
    :
    <div className={`downloads-page page-container`} >
      <ScrollToTop />
      <PageHero 
        eyebrow={data.hero.heroEyebrow} 
        header={data.hero.heroHeader}
        image={data.hero.heroImg}
        hasButton={data.hero.hasButton}
        button={data.hero.button}
        tall={false}
      />
      <section className="page-content-wrapper">
        <PageOverhang 
          title={data.pageOverhang.title}
          body={data.pageOverhang.body}
          sidenote={data.pageOverhang.sidenote}
        />
        <div className="page-content">
          <DownloadsSection 
              title= {data.downloadsSections[0].title}
              version = {data.downloadsSections[0].version}
              files = {data.downloadsSections[0].files}
              content = {data.downloadsSections[0].content}
          />
          {data.downloadsSections.slice(1).map(({title, version, files, content}, i) =>
            <DownloadsSection 
              key = {i}
              title= {title}
              version = {version}
              files = { files }
              content = {content}
            />
          )}
        </div>
        <Subscribe />
      </section>
    </div>
  )
}