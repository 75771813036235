import React, { useEffect, useState } from 'react';
import _ from "lodash";
import Papa from 'papaparse'
import { ScrollToTop } from 'utils/ScrollToTop';

// Utils
import { getData } from 'utils/sanity/'

// Queries
import { citationsQuery } from "utils/sanity/sanity-queries"

// Sections
import { PageHero } from 'components/PageHero'
import { CitationSection } from './CitationSection';
import { Subscribe } from 'components/Subscribe'
import { Loader } from 'components/Loader';

// Style
import './index.scss'
import { PageOverhang } from 'components/PageOverhang';

export const Citations = () => {

  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [citationSections, setCitationSections] = useState([]);
  const [citationSectionKeys, setCitationSectionKeys] = useState([]);

  function parseCitations(url) {
    const config = {
      delimiter: ",",  // auto-detect
      newline: "",  // auto-detect
      quoteChar: '"',
      escapeChar: '"',
      header: true,
      step: undefined,
      complete: (results, file) => {
        // console.log("Parsing complete:", results, file)
        setCitationSections(_.groupBy(results.data, "Year"));
      },
      download: true,
      skipEmptyLines: false,
      transform: undefined,
      transformHeader: function(h) { 
        // Remove the parentheses from the header
        return h.replace( /[()$\\/]/g,''); 			
      }
    }

    Papa.parse(url, config)
  }

  useEffect(()=> {
    parseCitations(`https://docs.google.com/spreadsheets/d/e/2PACX-1vS3k7GrXAZDcGHLbT979YeCQH0HcfpkHCrlmIfgrY2snD5arry17GG_nHxopxnapZHrEeB4eeazdbXh/pub?output=csv`)
  },[])

  useEffect(()=>
  setCitationSectionKeys(_.keys(citationSections).reverse())
  ,[citationSections])

  // Get the data from Sanity
  useEffect(() => {
    async function fetchData() { 
      try {
        const dataResponse = await getData(citationsQuery)
        setData(dataResponse)
        setLoaded(true)
      }
      catch (err) {
        console.log('fetch failed', err);
        throw err
      }
    }
    fetchData()
  }, []);

    // Set the page title once
    useEffect(() => {
      document.title = "OpenBCI | Citations"
    }, [])

  return (!loaded ?
    <Loader /> 
    :
    <div className={`citations-page page-container`} >
      <ScrollToTop />
      {data.hero &&
      <PageHero 
        eyebrow={data.hero.heroEyebrow}
        header={data.hero.heroHeader}
        image={data.hero.heroImg}
        hasButton={data.hero.hasButton}
        button={data.hero.button}
        tall={false}
      />}
      <section className="page-content-wrapper">
        {data.pageOverhang &&
        <PageOverhang 
          title={data.pageOverhang.title}
          body={data.pageOverhang.body}
          sidenote={data.pageOverhang.sidenote}
        />
        }
        <div className='citation-list-container'>
          <div className='citation-list-header'>
            <h2 className='citation-list-header-title' >OpenBCI Official Citations List</h2>
            <div className='rule-orange'></div>
          </div>
          {citationSectionKeys.map((year, i) => {
            return (
            <CitationSection 
              year={year}
              citations={citationSections[year]}
              key={i}
            />
          )})}
        </div>
        <Subscribe />
      </section>
    </div>
  )
}