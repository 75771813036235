import React from 'react';

// Images
import headsetWebP from 'img/galea-side.webp'
import headsetPNG from 'img/galea-side.png'
import hero from 'img/hero-mid-galea.jpg'

// Images
import galeaLogo from 'img/galea-logo.svg'

// Style
import './index.scss'

export const ProductFeature = () => {

  return (
        <section className='product-feature' style={{backgroundImage: `url(${hero})`}}>
          <div className='product-feature-textblock'>
            <img className='galea-logo' src={galeaLogo} alt='galea logo'/>
            <h3 className='product-feature-title'>Bringing Next-Gen Neurotechnology to Mixed Reality</h3>
            <a className='text-arrow-link' href="https://www.galea.co">See Galea Headset<span>{`❯`}</span></a>
          </div>
          <div className='product-feature-img-container'>
            <picture className='product-feature-img' id='product' alt='galea-hero' height='1312' width='1852'>
              <source className='product-feature-img' type="image/webp" src={headsetWebP} height='1312' width='1852' />
              <img className='product-feature-img' src={headsetPNG} alt="" height='1312' width='1852'/>
            </picture>
          </div>
        </section>
  )
}