import React from 'react';

import { BodyContent } from 'components/Sanity';


// Style
import './index.scss'

export const CommunityGrid = ({communityPosts, title, eyebrow}) => {

  return (
        <section className='community-grid' >
          <div className='community-grid-title' >
            {eyebrow && <div className='eyebrow'>{eyebrow}</div>}
            {title && <h2>{title}</h2>}
          </div>
          {communityPosts.map(({title, image, body, url}, i) =>
            <React.Fragment key={i}>
              <a className='grid-item' href={url}>
                <div className='grid-item-image' style={{backgroundImage: `url(${image})`}} />
              </a>
              <div className='grid-item' key={i + .5}>
                <div className='community-grid-item-content'>
                  <a className='community-header' href={url} ><h3>{title}</h3></a>
                  <div className='rule-orange' />
                  <BodyContent blocks={body} />
                </div>
              </div>
            </React.Fragment>
          )}
          <div className='grid-item' />
        </section>
  )
}