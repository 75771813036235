import { BodyContent } from 'components/Sanity';
import React from 'react';
import { useMeasure, useWindowSize } from 'react-use';

// Style
import './index.scss'

export const PageOverhang = ({title, body, author, date, sidenote, children}) => {
  const [ref, { height }] = useMeasure();
  const { width } = useWindowSize();

  return (
    <>
    <section className="page-overhang">
      <div className='overhang-flex-container'>
        <div className="overhang-primary-content">
          <div className='overhang-title-measure' ref={ref}>
            <h2>{title}</h2>
            {date && <h5>By {author} | {date}</h5>}
            <div className="rule-orange" />
          </div>
          <BodyContent blocks={body} />
        </div>
        <div className="overhang-support-sidenote">
          {sidenote &&
            <>
            <div className='sidenote-alt'>
              {sidenote.alt && <div className='support-sidenote-alt-text'>{sidenote.alt}</div>}
              {sidenote.credit && <div className='support-sidenote-credit'>Credit: <a href={sidenote.credit.url}>{sidenote.credit.name}</a></div>}
            </div>
              <div className='sidenote-main' style={{marginTop: width > 1000 ? height + 160 : '0px'}}>
                {sidenote.sidenoteTitle && <h5>{sidenote.sidenoteTitle}</h5>}
                {sidenote.content && <BodyContent blocks={sidenote.content} />}
                {sidenote.sidenoteButton && 
                  <a className="button" href={sidenote.sidenoteButton.url}>{sidenote.sidenoteButton.text}</a>
                }
              </div>
            </>
          }
        </div>
      </div>
    {children && 
      <div className='overhang-additional-content overhang-primary-content'>
        {children}
      </div>
    }
    </section>
    </>
  )
}