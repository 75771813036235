import React, { useEffect, useState, useRef } from 'react';
import { ScrollToTop } from 'utils/ScrollToTop';

// Utils
import { getData } from 'utils/sanity/'

// Queries
import { homepageQuery } from "utils/sanity/sanity-queries"

// Sections
import { CustomHero } from './CustomHero'
import { Feature } from './Feature'
import { CitationsCarousel } from './CitationsCarousel'
import { CommunityGrid } from './CommunityGrid'
import { ProductFeature } from './ProductFeature'
import { Testimonials } from './Testimonials'
import { ShopFeature } from './ShopFeature'
import { CaseStudiesGrid } from './CaseStudiesGrid'
import { Subscribe } from 'components/Subscribe'
import { Loader } from 'components/Loader';

// Style
import './index.scss'



export const Home = () => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const featureRef = useRef(null)
  
  // Set the page title once
  useEffect(() => {
    document.title = "OpenBCI | Home"
  }, [])
  

  const scrollToClick = () => window.scrollTo({top: featureRef.current.offsetTop  - 68, behavior: 'smooth', easing: 'easeInOut'})

  // Get the data from Sanity
  useEffect(() => {
    async function fetchData() { 
      try {
        const dataResponse = await getData(homepageQuery)
        setData(dataResponse)
        setLoaded(true)
      }
      catch (err) {
        console.log('fetch failed', err);
        throw err
      }
    }
    fetchData()
  }, []);

  return ( !loaded ?
    <Loader /> 
    :
    <div className={`home-page`} >
      <ScrollToTop />
      <CustomHero 
        eyebrow={data.hero.heroEyebrow} 
        header={data.hero.heroHeader}
        button={data.hero.heroButton}
        tall={true}
        scrollToClick={scrollToClick}
      />
      <Feature 
        image={data.callout.image}
        backgroundPosition={data.callout.backgroundPosition}
        title={data.callout.title}
        body={data.callout.content}
        button={data.callout.link}
        featureRef={featureRef}
      />
        <CitationsCarousel 
          citations={data.homeCitations}
        /> 
      <CommunityGrid 
        title={data.communityGrid.title}
        eyebrow={data.communityGrid.eyebrow} 
        communityPosts={data.communityGrid.homeCommunityPosts}
      />
      <ProductFeature />
      <Testimonials 
        testimonials={data.testimonials}
        loaded={loaded}
      />
      <ShopFeature 
        shopFeature={data.shopFeature.shopFeatureMain}
        shopFeaturette={data.shopFeature.shopFeaturette}
      />
      <CaseStudiesGrid 
        eyebrow={data.homeCaseStudiesGrid.eyebrow}
        title={data.homeCaseStudiesGrid.title}
        categories={data.homeCaseStudiesGrid.categories}
      />
      <div className='page-content-wrapper'>
        <Subscribe />
      </div>
    </div>
  )
}