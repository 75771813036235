import React, { useEffect } from 'react';
import gsap from "gsap";
import ReactPlayer from 'react-player'

// Images
// import headsetWebP from 'img/headset-compressed.webp'
import headsetPNG from 'img/galea-hero.png'

// Video
import waves from 'img/waves.mp4'

// Style
import './index.scss';

export function CustomHero({eyebrow, header, button, scrollToClick}) {
  // make the headset float
  useEffect(() => {
    gsap.to('.hero-img',
    {
      y: 10,
      duration: 4,
      repeat: -1,
      ease: 'sine.inOut',
      yoyo: true
    })
    gsap.to('.hero-down-arrow',
    {
      y: 10,
      duration: 2,
      repeat: -1,
      ease: 'sine.inOut',
      yoyo: true
    })
  }, []);

  return (
    <section className='custom-hero'>
      <div className='hero-img-container'>
      <picture className='' id='product' alt='galea-hero' height='1312' width='1852'>
        {/* <source className='hero-img' type="image/webp" src={headsetWebP} height='1312' width='1852' /> */}
        <img className='hero-img' src={headsetPNG} alt="" height='1312' width='1852'/>
      </picture>
      </div>
      <div className='hero-content-container'>
        <div className='hero-eyebrow'>{eyebrow}</div>
        <h1>{header}</h1>
        <div className='rule-white' />
          <a className='button' href={button.url}>{button.text}</a>
        <div className='hero-scrim' />
        <button className='hero-down-arrow-container' onClick={scrollToClick}> 
          <svg version="1.1"
            className='hero-down-arrow' 
            id="Layer_1" 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            x="0px" 
            y="0px"
            viewBox="0 0 21.9 11.91" 
            xmlSpace="preserve"
              >
          <polygon className="st0" points="21.9,0.98 20.91,0 10.85,9.95 0.99,0.19 0,1.17 10.86,11.91 11.84,10.93 11.84,10.93 "/>
          </svg>
        </button>
      </div>      
      <div className='hero-video-container'>
        <ReactPlayer className='hero-video' url={waves} playing loop muted playsinline height='100vh' width='auto' />  
      </div>
    </section>
  );
}