import React from 'react';

// Style
import './index.scss'

export const Feature = ({title, body, button, featureRef, image, backgroundPosition}) => {

  return (
        <section className='home-feature' ref={featureRef} >
          <div className='home-feature-background' style={{backgroundImage: `url(${image})`}} />
          <div className='home-feature-card'>
            <h2>{title}</h2>
            <div className='rule-orange' />
            <p>{body}</p>
            <a className='button' href={button.url} target={button.blank ? '_blank' : '_self'} rel='noreferrer'>{button.text}</a>
          </div>
        </section>
  )
}