import React from 'react';

import './index.scss'

export function Loader() {
  return (
    <div className='full-page-loader'>
        <svg className='loader-logo' version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 100 100" xmlSpace="preserve">
          <g id="loader-logo-gear">
            <path className="st0" d="M99.8,52v-3.5l-6.7-4.6c-0.2-1.5-0.5-3.1-0.8-4.6l4.4-6.5l-1.4-3.3l-7.9-1.8c-0.8-1.3-1.7-2.6-2.6-3.8l1.7-7.8
              l-2.4-2.4l-8,1.4c-1.2-0.9-2.5-1.8-3.8-2.6l-1.4-7.8l-3.3-1.4l-6.8,4.4c-1.5-0.4-3-0.7-4.6-0.9L51.8,0h-3.5l-4.6,6.7
              c-1.5,0.2-3.1,0.5-4.6,0.9l-6.5-4.5l-3.3,1.4l-1.8,7.9C26.3,13.2,25,14,23.8,15L16,13.3l-2.4,2.4l1.5,8c-0.9,1.2-1.8,2.5-2.6,3.8
              L4.5,29l-1.3,3.3l4.4,6.8c-0.4,1.5-0.7,3-0.9,4.6L0,48.1v3.5l6.7,4.6c0.2,1.5,0.5,3.1,0.8,4.6l-4.4,6.5l1.4,3.3l7.9,1.8
              c0.8,1.3,1.7,2.6,2.6,3.8L13.3,84l2.4,2.4l8-1.4c1.2,1,2.5,1.8,3.8,2.6l1.4,7.8l3.3,1.4l6.8-4.4c1.5,0.4,3,0.7,4.6,0.9L48,100h3.5
              l4.6-6.7c1.5-0.2,3.1-0.5,4.6-0.8l6.5,4.5l3.3-1.4l1.8-7.9c1.3-0.8,2.6-1.7,3.8-2.6l7.8,1.7l2.4-2.4l-1.4-8.1
              c0.9-1.2,1.8-2.5,2.6-3.8l7.8-1.4l1.4-3.3l-4.4-6.8c0.4-1.5,0.6-3,0.8-4.6L99.8,52z M76,76.1c-14.4,14.4-37.7,14.4-52.1,0
              c-14.4-14.5-14.4-37.8,0-52.2C38.2,9.5,61.6,9.5,76,23.9C90.3,38.3,90.3,61.7,76,76.1z"/>
            <path className="st0" d="M49.5-234.2L49.5-234.2z"/>
            <path className="st0" d="M20.1-222.9L20.1-222.9z"/>
          </g>
          <g id="loader-logo-brain">
            <path className="st0" d="M44.2,66.9c-0.3,0.4-0.3,1,0.1,1.3c0.4,0.3,0.9,0.3,1.3-0.1h0.5c1.5,0,3-0.2,4.4-0.6c-0.9,0.9-2.1,1.5-3.4,1.6
              c-0.5,0-1,0.2-1.4,0.5l-0.1,0.1c-1.3,0.9-2.8,1.5-4.3,1.6c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.2-0.7,0.4c-0.3,0.4-0.2,1,0.2,1.2
              c0.4,0.3,1,0.2,1.2-0.2l0.1,0c0.1,0,0.1,0,0.2,0c0.9-0.2,1.8-0.4,2.6-0.8c-0.3,0.7-0.7,1.3-1,2c-0.8,0.3-1.6,0.5-2.5,0.6
              c-0.1-0.1-0.2-0.2-0.2-0.2c-0.4-0.3-1-0.1-1.2,0.3c-0.2,0.4-0.1,1,0.3,1.2c0.4,0.3,1,0.1,1.2-0.3h0c0.1,0,0.2,0,0.2,0
              c0.5-0.1,1.1-0.2,1.6-0.4c-0.3,0.6-0.6,1.3-0.8,1.9c-0.5,0.2-1.1,0.3-1.6,0.3c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.2-0.7,0.4
              c-0.3,0.4-0.2,1,0.2,1.2c0.4,0.3,1,0.2,1.2-0.2l0.1,0c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.1,0.8-0.2c-0.3,0.6-0.5,1.3-0.7,1.9
              c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.3-0.4,0.5-0.7,0.5l-0.1,0c-0.6-0.1-1.3-0.1-1.9-0.3c-0.5-0.2-0.7-0.7-0.6-1.2
              c0.1-0.4,0.2-0.8,0.2-1.2c0.2-1.1,0.4-2.2,0.7-3.3c0.3-0.8,0-1.6-0.6-2.1c-0.2-0.1-0.3-0.3-0.4-0.5c-0.2-0.2-0.5-0.5-0.8-0.6
              c0.3-0.1,0.7-0.2,1-0.4c0.1,0,0.1-0.1,0.2-0.1l0.1,0c0.3,0.1,0.5,0.1,0.8-0.1c0.4-0.2,0.6-0.8,0.3-1.2s-0.8-0.6-1.2-0.3
              c-0.2,0.1-0.4,0.4-0.4,0.7c-1.2,0.7-2.5,1-3.8,1c-0.1,0-0.2,0-0.3,0c-0.8-0.1-1.6-0.3-2.3-0.6c-0.6-0.2-1.2-0.5-1.7-0.8
              c0.5-0.3,1-0.6,1.6-0.7c0.1,0.1,0.1,0.1,0.2,0.1c0.4,0.2,1,0,1.2-0.4c0.2-0.5,0-1-0.4-1.2c-0.5-0.2-1,0-1.2,0.4
              c-0.4,0.1-0.7,0.2-1.1,0.3l-0.2,0.1c-0.3,0.1-0.6,0.2-1,0.3l-0.2,0c-0.2,0.1-0.5,0-0.7-0.1c-0.2-0.2-0.4-0.3-0.6-0.5
              c-0.2-0.2-0.4-0.3-0.6-0.5l-0.1,0.1l0.1-0.2l0,0l0,0l0,0c0,0,0,0,0,0c0.2-0.1,0.4-0.2,0.6-0.3c0.3-0.1,0.8-0.3,1.4-0.6
              c0.3-0.2,0.6-0.3,0.8-0.4l0,0c0.4-0.3,0.9-0.5,1.4-0.5c1.4-0.2,2.7-0.5,4.2-0.7l0.3,0c0.3-0.1,0.7-0.1,1,0c0,0.1,0.1,0.2,0.1,0.2
              c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.5,0.3,1,0.8,1s1-0.3,1-0.8c0-0.5-0.3-1-0.8-1c0,0,0,0,0-0.1l0,0c-0.1-0.3-0.2-0.6-0.1-0.9
              c0-0.1,0-0.2,0.1-0.3c0.1,0,0.2-0.1,0.3-0.2c0.4-0.3,0.5-0.8,0.2-1.2c-0.3-0.4-0.9-0.5-1.2-0.2c-0.4,0.3-0.5,0.8-0.2,1.2
              c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.2-0.2,0.2-0.8,0.3l-0.3,0C34,63.9,33.5,63.9,33,64l-0.1,0c-0.9,0.1-1.8,0.2-2.6,0.4l-0.1,0
              c-0.2,0.1-0.5,0-0.7-0.1c0,0-0.2-0.1-0.4-0.2l0,0c0-0.1,0-0.2,0-0.2c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9
              c0.1,0,0.2,0,0.3,0c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-2.5,1.4-3.3,1.3-3.4,1.2c-0.2-0.4-0.4-0.7-0.5-1
              c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.2,0-0.3-0.1-0.5c0-0.1-0.1-0.2-0.1-0.3c1.8-0.4,3.6-1,5.2-1.8c1.5-0.7,3.1-0.9,4.8-0.8
              c-0.6,0.5-1.2,0.8-2,1c-0.2-0.2-0.5-0.2-0.7-0.2c-0.3,0.1-0.5,0.3-0.6,0.5c-0.2,0.5,0,1,0.5,1.2c0.5,0.2,1-0.1,1.2-0.5l0.1,0
              c0.6-0.2,1.2-0.5,1.8-0.9l0.3-0.2c0.4-0.3,0.9-0.5,1.4-0.7c0.1-0.1,0.3-0.1,0.4-0.1h0.1c0.6,0.1,1,0.1,1.3,0.1
              c0.6,0.1,1.3,0.1,1.9,0.3c0.8,0.1,2.7,0.6,4,1c-1.2,0.7-2.6,1-4.1,1h-0.1c0,0-0.1,0-0.1-0.1c-0.4-0.3-1-0.2-1.2,0.2
              c-0.3,0.4-0.2,1,0.2,1.2c0.4,0.3,1,0.2,1.2-0.2l0,0l0.1,0H40c0.1,0,0.2,0,0.2,0c1.2-0.1,2.3-0.4,3.4-0.9l0.2-0.1
              c0.3-0.2,0.7-0.3,1-0.4c0.3-0.1,0.6-0.1,0.9,0c0.5,0.2,0.9,0.5,1.3,0.7l0.1,0l0.2,0.2c-1.7,0.4-3.4,1-4.9,1.8l-0.8,0.4
              c-0.3,0.1-0.4,0.2-0.6,0.3l-0.3,0.2c-1,0.4-2.1,0.6-3.3,0.7c-0.4,0-0.7,0.1-1.1,0.1l-0.2,0c-0.5,0.1-1,0.1-1.5-0.1
              c-0.2-0.2-0.4-0.4-0.5-0.5c0-0.1,0-0.1,0-0.2c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9c0.1,0,0.2,0,0.3,0
              c0.1,0.1,0.1,0.2,0.2,0.2c0.3,0.3,0.4,0.7,0.3,1.1c-0.4,0.1-0.7,0.5-0.7,0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9
              c0-0.2-0.1-0.4-0.2-0.6c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.1,0.6-0.1l0.3,0l1-0.1c0.5,0,0.8-0.1,1.4-0.1l0.8,0c0.1,0,0.1,0,0.2,0h0.1
              c0.2,0,0.4,0,0.5,0.1c0,0,0.2,0.1,0.3,0.2l0.1,0.1l0.2,0.1c0,0.1,0,0.1,0,0.2c0,0.5,0.4,0.9,0.9,0.9S42,69.5,42,69
              s-0.4-0.9-0.9-0.9c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.2-0.2-0.4-0.4c0.2-0.1,0.5-0.2,0.7-0.3c0.1,0,0.2-0.1,0.3-0.1
              c0.1,0,0.6-0.3,1-0.5c2.5-1.3,5.7-2.3,6.8-1.6l0.1,0.1c0.6,0.4,1.1,0.6,1.6,0.9c-1.8,0.6-3.6,0.8-5.4,0.7c-0.2-0.2-0.4-0.3-0.6-0.4
              C44.7,66.5,44.4,66.6,44.2,66.9z M67.8,23.6c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0.6,0.8,1.2,1.2,1.8c0.4,0.6,0.9,1.2,1.3,1.9
              c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.3,0.4,0.3c0.5,0.2,0.9,0.3,1.2,0.4c0.4-0.3,1-0.2,1.3,0.2c0.3,0.4,0.2,1-0.2,1.2
              c-0.4,0.3-1,0.2-1.2-0.2c0,0-0.1-0.1-0.1-0.1l-0.5-0.2c-0.2-0.1-0.5-0.1-0.7,0c-0.5,0.3-0.8,0.9-0.8,1.5c0,0.1,0,0.2,0.1,0.3l0.1,0
              c0.6,0.6,1,1,1.7,0.8c0.9-0.3,1.7-0.6,2.5-0.9c0.1-0.1,0.3-0.1,0.5-0.1c0.4,0,0.7,0.2,0.9,0.4c0.6,0.6,1.1,1.1,1.7,1.6
              c0.2,0.2,0.4,0.3,0.5,0.5c0.3-0.6,0.3-0.6-0.1-1.2c-0.3-0.4-0.6-0.8-0.9-1.2c-0.2-0.2-0.3-0.4-0.5-0.6c-1.1-1.4-2.5-2.6-4.2-3.5
              l0,0c-0.2-0.1-0.4-0.2-0.8-0.4c-0.5,0.2-1,0-1.2-0.4c-0.2-0.5,0-1,0.4-1.2c0.5-0.2,1,0,1.2,0.4c0,0,0,0.1,0,0.2
              c0.2,0.1,0.3,0.1,0.5,0.2c0.8,0.3,1.5,0.7,2.1,1.2c-0.2-0.3-0.5-0.6-0.8-0.8c-0.6-0.4-1.2-0.9-1.8-1.3l-0.6-0.5
              C69.7,23.1,67.9,22,66,21c0.5,0.5,0.9,1.2,1.2,1.8C67.5,23.1,67.6,23.4,67.8,23.6z M59.9,20.4c0.4,0.4,0.8,0.8,1.4,1.3
              c0.1,0.1,0.2,0.2,0.2,0.2c0.3,0.4,0.4,0.4,1.3,0.3l0.1,0c0.6-0.1,1.2-0.2,1.8-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0v0
              c0-0.3-0.2-0.6-0.5-0.8l-0.2-0.2c-0.5-0.8-1.2-1.4-2.1-1.8c-1.5-0.5-3.1-0.8-4.7-0.8h-0.1c0.1,0.1,0.3,0.3,0.5,0.5
              C59,19.5,59.4,20,59.9,20.4z M80.8,47.7c0,0.1-0.1,0.3-0.1,0.4l0,0c-0.1,0.4-0.2,0.8-0.4,1.2c0-0.2-0.1-0.3-0.3-0.4
              c-0.1,0-0.2,0-0.2,0c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.5,0,1.1-0.1,1.6c0,0.1-0.1,0.2-0.1,0.4l0,0.1c-0.7,1.9-2.7,3-4.7,2.6l0.1-0.7
              c0-0.1,0-0.2-0.1-0.2c-0.1-0.1-0.2,0-0.3,0c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2,0.6-0.8,0.9-1.4,0.8c-0.2,0-0.4,0-0.6-0.1
              c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.6-0.3-0.8-0.5h0c-0.2-0.2-0.3-0.5-0.1-0.8c0,0,0-0.1,0-0.1c0-0.1-0.2-0.2-0.3-0.2l-0.3,0
              c-0.3,0-0.6,0.1-0.9,0.1c-0.3,0.1-0.6,0-0.8-0.3c-0.4-0.5-0.9-1-1.3-1.5l-0.5-0.6c0,0,0.1,0,0.1-0.1c0.2-0.1,0.7-0.4,1.3-0.7
              c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.3,0.9-0.7c0.1-0.5-0.2-1-0.7-1.1c-0.5-0.1-1,0.2-1.1,0.7l-0.1,0.1c-0.2,0.1-0.5,0.3-0.8,0.4
              c-0.2,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.4,0.2-0.6,0.1c-0.5-0.1-1-0.4-1.3-0.8c-0.5-0.5-0.9-1-1.3-1.6c0,0,0-0.1-0.1-0.1
              c-0.2-0.3-0.1-0.8,0.2-1c0.2-0.2,0.4-0.5,0.6-0.8c0.5,0,0.8-0.4,0.8-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9
              c0,0.1,0,0.3,0.1,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c-0.3,0.2-0.7,0.4-1,0.5c-0.5,0.1-1-0.2-1.1-0.7c-0.1-0.3-0.2-0.6-0.3-0.9
              c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.5-0.1-1-0.6-1.1c-0.5-0.2-1,0.1-1.1,0.6
              c-0.2,0.5,0.1,1,0.6,1.1c0,0,0,0.1,0,0.1c0.1,0.3,0.1,0.6,0.1,0.9c0,0,0,0,0,0.1c0,0,0,0-0.1,0c-0.6-0.1-1.2-0.2-1.8-0.4
              c-1-0.3-2-0.7-3-1.2c-0.2-0.1-0.4-0.2-0.7-0.3l0,0c0,0,0,0,0-0.1c0-0.1,0-0.2,0.1-0.2c0.2-0.2,0.5-0.5,0.7-0.7h0.1
              c0.2,0,0.5-0.1,0.6-0.3c0.3-0.4,0.3-1-0.1-1.3c-0.2-0.2-0.4-0.2-0.7-0.2c-0.5,0-0.9,0.5-0.8,1v0c-0.1,0.1-0.3,0.3-0.4,0.4
              c-0.2,0.2-0.5,0.5-0.8,0.7c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1l-0.7-0.4c-0.6-0.3-1.2-0.6-1.8-0.9
              c-0.8-0.4-1.4-0.7-2.2-1L46,39.2c-0.4-0.2-0.6-0.3-0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.7c0.1,0,0.2-0.1,0.3-0.1c0.4-0.3,0.5-0.9,0.2-1.3
              c-0.3-0.4-0.9-0.5-1.3-0.2c-0.4,0.3-0.5,0.9-0.2,1.3c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.4c-0.2,0.4-0.6,0.4-1.3,0.2l-0.2,0
              c-0.2,0-0.4-0.1-0.7-0.1c-0.7-0.1-1.6-0.3-1.9-0.4c-0.5-0.1-1-0.5-1.2-1c-0.1-0.2-0.3-0.5-0.6-1c-0.2-0.6-0.3-1.2-0.2-1.8
              c0-0.1,0-0.2,0-0.2c0.1,0,0.1-0.1,0.2-0.1c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.3-0.9-0.3-1.3,0c-0.4,0.3-0.3,0.9,0,1.3l0,0.1
              c-0.1,0.6-0.1,1.2-0.2,1.7c0,0.3,0,0.6,0.2,0.8l0,0c0,0,0.1,0.1,0.1,0.2c0.2,0.5,0.5,0.9,0.9,1.4l0.1,0.1c0.2,0.3,0.3,0.5,0.1,0.7
              c-0.2,0.3-0.3,0.6-0.5,0.9c-0.1,0.2-0.2,0.3-0.3,0.5c-0.4,0.1-0.7,0.5-0.7,0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9
              c0-0.1,0-0.1,0-0.2c0.3-0.4,0.6-0.8,0.9-1.2c0.4-0.4,1.1-0.5,1.6-0.3c0.2,0,0.4,0.1,0.6,0.1c1.8,0.3,3.5,0.8,5.2,1.4
              c2,0.8,4,1.8,5.9,2.9l0.5,0.3c1.1,0.6,1.1,0.6,1,1c0,0.1,0,0.2,0,0.3c-0.1,0.2-0.1,0.3-0.2,0.5c0,0.1,0,0.2-0.1,0.3
              c-0.1,0-0.2,0.1-0.3,0.2c-0.4,0.3-0.5,0.8-0.2,1.2c0.3,0.4,0.9,0.5,1.3,0.2c0.4-0.3,0.5-0.9,0.2-1.3l0-0.1c0.1-0.3,0.2-0.5,0.2-0.8
              l0.1-0.2c0.2-0.5,0.3-0.6,2.2,0c0.5,0.2,1,0.3,1.3,0.4c0.9,0.2,1.6,0.7,2.1,1.4c0.1,0.1,0.2,0.3,0.3,0.4c0.5,0.6,1,1.2,1.5,1.9
              c0.2,0.4,0.2,0.8,0.2,1.2c0,0.2,0,0.4,0,0.6v0.6c-0.2,0.2-0.4,0.4-0.4,0.7c0,0.3,0.1,0.5,0.4,0.7c0.4,0.3,1,0.2,1.3-0.2
              c0.3-0.4,0.2-1-0.2-1.3l0-0.1c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2,0-0.3c0,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.1
              c0.2,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.2,0.2,0.3,0.4c0.6,0.6,0.9,1.6,0.6,2.4c-0.2,0.6-0.3,1.2-0.4,1.7c-0.1,0.5-0.2,1-0.3,1.5
              c-0.8,3.4-2.8,5.6-5.8,6.5c-3.8,1.4-8.1,0.9-11.5-1.2l-0.1-0.1c0.5-0.4,1-0.7,1.5-0.8c0.2,0.1,0.4,0.2,0.6,0.2
              c0.2,0,0.5-0.1,0.6-0.3c0.1-0.1,0.1-0.2,0.1-0.2c0.2-0.5,0-1-0.5-1.2c-0.5-0.2-1,0-1.2,0.5c-0.4,0.1-0.7,0.3-1.1,0.4l-0.2,0.1
              c-0.3,0.2-0.6,0.3-0.9,0.4l-0.1,0c-0.2,0.1-0.5,0.1-0.8-0.1L46,61.9c-0.8-0.4-1.6-0.6-2.4-0.8c-2.2-0.6-4.4-1-6.7-1.3
              c-1.5-0.2-2.7-0.2-3.5-0.3c-0.3,0-0.7,0-1.1,0c-0.8,0-1.6,0-2.2,0.1c-1,0.1-1,0.1-1.4-1.6l0-0.1c-0.1-0.3-0.1-0.6-0.2-1
              c-0.1-0.3-0.1-0.5,0-0.8c0-0.2,0.1-0.4,0.3-0.5c0.1,0,0.2,0,0.3,0c0.2,0.1,0.4,0.2,0.5,0.2l0.1,0.1c0.5,0.2,0.7,0.2,1-0.2
              c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.3,0.4-0.5c0,0,0.1-0.1,0.1-0.1h0c0.1,0,0.2,0,0.4-0.1c0.5-0.2,0.7-0.7,0.4-1.2
              c-0.2-0.4-0.7-0.6-1.2-0.4c-0.5,0.2-0.6,0.7-0.4,1.2c-0.2,0.2-0.5,0.5-0.6,0.6c-0.5-0.1-1-0.3-1.3-0.7L28.4,54
              c-0.3-0.3-0.6-0.6-0.8-0.9L27.5,53c-0.1-0.1-0.3-0.3-0.5-0.6c0.1-0.1,0.1-0.3,0.1-0.4c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9
              s0.4,0.9,0.9,0.9h0.1c0.3,0.4,0.7,1,0.9,1.4c0.2,0.3,0.3,0.6,0.3,0.9c-0.1,0.2-0.4,0.4-0.6,0.4l-0.2,0c-0.3,0.1-0.6,0.2-1,0.3
              c-0.1-0.1-0.2-0.2-0.3-0.2c-0.4-0.2-1,0-1.2,0.5c-0.2,0.5,0,1,0.5,1.2c0.5,0.2,1,0,1.2-0.5c0.2,0,0.3-0.1,0.4-0.1
              c0.4-0.1,0.4-0.1,0.6,0.3c0.5,0.9,0.8,1.9,0.8,2.9v0.1c0,0.3,0,0.3-0.7,0.8c-1.6,1.1-3.7,1.3-5.5,0.6c-0.9-0.3-1.5-1-1.8-1.8
              c0,0,0,0,0,0c0.7-0.8,0.7-0.8,1.2-0.5c0.2,0.1,0.5,0.3,0.8,0.4c0.5,0.3,1,0.5,1.5,0.7l0.1,0c0,0.1,0.1,0.2,0.2,0.3
              c0.3,0.4,0.9,0.4,1.3,0.1c0.4-0.3,0.4-0.9,0.1-1.3c-0.3-0.4-0.9-0.4-1.3-0.1c-2.8-1.3-2.9-1.4-3.3-2.5c0-0.2-0.1-0.3-0.2-0.5
              c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.5-0.2-1-0.7-1c-0.5-0.1-1,0.2-1,0.7c-0.1,0.5,0.2,1,0.7,1c0,0.1,0.1,0.2,0.1,0.2
              c0.2,0.2,0.2,0.4,0.2,0.6l-0.1,0.1c-0.4,0.3-0.5,0.4-0.7,0.2c-0.2-0.2-0.3-0.4-0.4-0.6c-0.9-2-1.3-4.2-1.1-6.4l0-0.1
              c0-0.2,0-0.3,0.1-0.5c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.5,0c0.4,0,0.8,0.1,1.2,0.2l0,0c0.2,0.1,0.4,0.3,0.4,0.6
              c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1,0.5,0.2,1,0.7,1.1c0.5,0.1,1-0.2,1.1-0.7c0.1-0.5-0.2-1-0.7-1.1
              c0-0.1,0-0.2-0.1-0.3l0-0.1c-0.3-0.8-0.2-1.7,0.3-2.3c0.2,0,0.3-0.1,0.4-0.2c0.4-0.3,0.5-0.9,0.2-1.3c-0.3-0.4-0.9-0.5-1.2-0.2
              c-0.4,0.3-0.5,0.9-0.2,1.3c-0.1,0.1-0.2,0.3-0.3,0.5l-0.2,0.3c-0.3-0.1-0.8-0.2-1.2-0.3c-0.5-0.1-1.1-0.3-1.6-0.5
              c-0.4-0.2-0.4-0.4-0.4-1v-0.1c0-1.5,0.2-3,0.8-4.5l0,0c0.1-0.3,0.1-0.3,0.4-0.2c0.7,0.1,1.3,0.6,1.6,1.2c0.3,0.3,0.6,0.5,1,0.5
              c0.7,0.1,1.4,0.2,2.1,0.4c0.2,0,0.4,0.1,0.6,0.1l0.6,0.1c0.2,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.2,0,0.3,0.5
              c0.1,0.4,0.1,0.8,0.2,1l0,0.1c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.3,0.2,0.6,0.4,0.8c0.4,0.3,1,0.1,1.2-0.3c0.3-0.4,0.1-1-0.3-1.2
              c0-0.1,0-0.1,0-0.2c-0.2-0.4-0.2-1,0-1.4l0.1-0.1c1.2-1,2.6-1.8,4.1-2.2c0.8-0.2,1.6,0,2.2,0.6c0.3,0.4,0.6,0.8,0.9,1.2
              c0.3,0.4,0.6,0.8,0.9,1.2c0.1,0.2,0.2,0.3,0.4,0.5c0.1,0.2,0.3,0.4,0.4,0.5c0.4,0.3,0.4,0.8,0.2,1.2c-0.1,0.8-0.6,1.4-1.3,1.6
              c-0.5,0.1-0.9,0.2-1.4,0.3c-0.2,0-0.5,0.1-0.7,0.2c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.8,0.5c-0.2,0.4-0.1,1,0.3,1.2
              c0.4,0.2,1,0.1,1.2-0.4c2.6-0.5,3-0.4,3.8,0.3l0.1,0.1c0,0,0,0.1,0,0.1c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9
              c0-0.5-0.4-0.9-0.9-0.9l-0.2-0.2l0-0.1c0,0-0.1-0.1-0.1-0.1c-0.3-0.2-0.5-0.7-0.3-1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.2
              c0.4-0.8,0.5-1,1.1-0.9l0.2,0c0.7,0.1,1.4,0.3,2.1,0.5l0.7,0.2c1.2,0.3,2.2,0.6,3.6,1c0.2,0,0.7,0.2,0.9,0.3c0.1,0,0.2,0.1,0.3,0.2
              c0,0.2-0.1,0.4-0.3,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c0,0-0.1,0-0.2,0c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9
              c0,0,0-0.1,0-0.1l0.1-0.1l0.2-0.2c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.3-0.2,0.5-0.1
              c2.8,1.1,5.7,2.3,8.1,3.5c0.5,0.2,2,1,3.1,1.6c0.1,0.1,0.3,0.1,0.4,0.2l0,0c0.4,0.1,0.7,0.5,0.7,0.9c0.1,0.2,0.2,0.4,0.2,0.5
              L59,57.3c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.4,0.2,0.6c0.3,0.4,0.9,0.4,1.3,0.1c0.4-0.3,0.4-0.9,0.1-1.3l-0.4-0.2l0,0
              c-0.1-0.1-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.4-0.1-0.4,0.3-0.7c0.1,0,0.1-0.1,0.2-0.1c0.1,0.1,0.3,0.1,0.4,0.1
              c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v0c-0.1,0-0.3,0.1-0.4,0.1c-0.5,0.3-1.2,0.2-1.6-0.2
              c-0.6-0.4-2.2-1.2-2.5-1.3C54,52,52.8,51.5,52,51.1c-0.6-0.3-1-0.4-1.4-0.5c-0.4-0.2-0.8-0.3-1.4-0.6c-0.3-0.1-0.6-0.2-0.8-0.5
              c0-0.2,0-0.5,0.1-0.7l0,0c0-0.2,0-0.3,0.1-0.5c0.4-0.1,0.6-0.5,0.6-0.8c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9
              c0,0.2,0.1,0.5,0.3,0.6c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.4-0.2,0.6-0.4,0.5c-1-0.2-1.9-0.5-2.9-0.8c-0.5-0.2-1.1-0.3-1.6-0.5
              c-0.4-0.1-0.9-0.2-1.3-0.3l-0.1,0c-1.3-0.3-2.6-0.7-3.9-1.1c-0.4-0.1-0.8-0.4-1.2-0.7C35,44.3,34,43,33.1,41.5
              c-0.1-0.2-0.2-0.4-0.3-0.5c-0.4-0.4-0.5-1-0.4-1.6V39c0-0.7,0.1-1.5,0.2-2.2c0.1,0,0.2-0.1,0.2-0.2c0.3-0.3,0.4-0.9,0-1.3
              c-0.4-0.4-0.9-0.4-1.3,0c-0.3,0.3-0.3,0.9,0,1.3c-0.1,0.5-0.2,1.1-0.3,1.7C31.1,39,31,39.5,30.9,40c0,0.3-0.2,0.6-0.6,0.7
              c-0.1,0-0.2,0.1-0.3,0.2c-0.8,0.4-1.6,0.8-2.4,1.2c-0.7,0.4-1.5,0.7-2.2,1.1c-0.1,0.1-0.3,0.1-0.5,0.1c-0.6-0.1-1.1-0.2-1.7-0.2h0
              c-0.6-0.1-1.2-0.1-1.9-0.2c-0.3,0-0.3-0.1-0.2-0.3c0-0.2,0.1-0.3,0.1-0.5l0.1-0.2c0.1,0,0.2-0.1,0.3-0.2c0.4-0.3,0.4-0.9,0.1-1.3
              c-0.3-0.4-0.9-0.4-1.3-0.1C20,40.5,20,41,20.3,41.4c0,0,0,0,0,0l-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.6-0.3-1.1-0.9-1.2-1.5
              c-0.1-0.3,0-0.7,0-1c0.2-0.8,0.4-1.6,0.7-2.4c0.1-0.5,0.5-0.9,0.9-1.1c0.7,0,1.3,0.2,1.8,0.6c0.6,0.4,0.6,0.4,0.7,0.7l0,0.1
              c0,0.4,0,0.8,0,1.2c-0.2,0.2-0.4,0.4-0.4,0.7c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9c0-0.3-0.1-0.5-0.4-0.7
              c-0.1-0.4,0-0.8,0.1-1.1c0.2-0.1,0.5-0.1,0.7,0h0c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0,0.2,0
              c0.3,0,0.6,0.1,0.6,0.4c0.1,0.2,0.1,0.3,0.2,0.5l0,0.1C27,38.8,27,38.9,27,39c-0.1,0.5,0.2,1,0.7,1c0.5,0.1,1-0.2,1-0.7
              c0.1-0.5-0.2-1-0.7-1.1c-0.1-0.3-0.2-0.7-0.3-1c0-1.5,0.3-2.9,0.9-4.2c0.9-1.8,2.2-3.3,3.9-4.4l0.3-0.2c0.2-0.2,0.2-0.2,0.8-0.1
              l0.6,0.1c0.5,0.1,0.9,0.1,1.3,0.2c0,0.2-0.1,0.3-0.1,0.5c-0.1,0-0.2,0.1-0.3,0.2c-0.4,0.3-0.4,0.9-0.1,1.3c0.3,0.4,0.9,0.4,1.3,0.1
              c0.4-0.3,0.4-0.9,0.1-1.3c0.1-0.2,0.2-0.4,0.4-0.6c1.2-0.2,2.5-0.2,3.8-0.2h0.1c0.4,0,0.4,0,0.5,0.3c0,0.1,0.1,0.2,0.1,0.3
              c0.2,0.3,0.3,0.7,0.5,1.2c0.1,0.3,0.3,0.7,0.5,1.1c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.2,0.5-0.3,0.6c-0.1,0-0.3,0.1-0.4,0.1
              c-0.4,0.3-0.5,0.9-0.2,1.2c0.3,0.4,0.9,0.5,1.2,0.2c0.4-0.3,0.5-0.9,0.2-1.3c0,0,0-0.1,0-0.1c0.1-0.4,0.5-0.7,0.9-0.7
              c0.2,0,0.4,0,0.5,0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.3,0.5,0.6,0.8,0.6c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9
              c-0.2,0-0.5,0.1-0.7,0.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5-0.1-0.6-0.3l-0.3-0.5c-0.5-0.8-0.7-1.1-1.4-2.3
              c-0.3-0.6-0.5-1.2-0.6-1.8c0,0,0.1-0.1,0.1-0.2c0.2-0.4,0.1-1-0.4-1.2c-0.4-0.2-1-0.1-1.2,0.4c-0.2,0.4-0.1,1,0.4,1.2
              c0,0.1,0.1,0.2,0.1,0.4l0,0.1c0.1,0.2,0.1,0.4,0.1,0.5c-0.2,0.1-0.5,0.1-0.8,0.1h-0.4l-0.9,0c-1.3,0.1-2.7,0-4-0.2l-0.2,0
              c-0.5,0-1-0.2-1.3-0.7c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.1-0.2c0.1-0.5-0.2-1-0.7-1.1c-0.5-0.1-1,0.2-1.1,0.7
              c-0.1,0.5,0.2,1,0.7,1.1l0.3,0.6l-0.4,0.4c-0.9,0.7-1.7,1.4-2.4,2.2c-1.5,1.6-2.1,4.2-2.5,6l0,0.1c-0.1,0.3-0.1,0.3-0.2,0.4
              c-0.9,0.1-1.8,0-2.5-0.4c-0.8-0.3-0.8-0.4-0.3-1.9l0-0.1c0-0.1,0.1-0.2,0.1-0.3c0.4-0.1,0.8-0.5,0.8-0.9c0-0.5-0.4-0.9-0.9-0.9
              c-0.5,0-0.9,0.4-0.9,0.9c0,0.2,0,0.3,0.1,0.5c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.4-0.2,0.5c-0.1,0.2-0.3,0.4-0.6,0.4
              c-0.3,0-0.5-0.1-0.7-0.3c-0.1-0.3-0.1-0.6,0.1-0.8c1-1.8,2.5-3.9,4.8-4.6c0.1-0.1,0.2,0,0.3,0c0,0.1,0,0.2,0,0.3
              c-0.1,0.2-0.1,0.5-0.2,0.7l0,0c-0.1,0-0.3,0.1-0.4,0.2c-0.4,0.3-0.5,0.9-0.2,1.3c0.3,0.4,0.8,0.5,1.3,0.2c0.4-0.3,0.5-0.9,0.2-1.3
              c0-0.1,0.1-0.2,0.2-0.4c0.1-0.3,0.3-0.5,0.4-0.7c0.2-0.4,0.5-0.7,0.9-0.8c0.1,0.1,0.2,0.2,0.3,0.2c0.5,0.1,1-0.1,1.1-0.6
              c0.2-0.5-0.1-1-0.6-1.1c-0.5-0.1-1,0.1-1.1,0.6h0c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.4,0.1-0.5,0.1c-0.1-0.3,0-0.5,0.4-0.9l0.6-0.5
              c0.1-0.1,0.3-0.2,0.4-0.3c0.5-0.5,1-0.9,1.5-1.2c0.3-0.2,0.5-0.4,0.8-0.5c0.7-0.5,1.5-1,2.2-1.3l0.6-0.3c0.8-0.6,1.8-0.5,2.5,0.1
              c0.2,0.1,0.5,0.3,0.7,0.5c0,0.1,0,0.1,0,0.2c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-0.1l-1.5-1
              c0.2-0.2,0.3-0.3,0.5-0.5c1.2-1.1,2.8-1.5,4.3-1.3c0.7,0.1,1.2,0.4,1.6,1c0.7,0.9,1.3,1.9,1.8,2.9c0.5,0.8,1,1.7,1.6,2.5
              c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.2,0.2c-0.3,0.1-0.6,0.2-0.9,0.4c0,0-0.1,0-0.1,0c-0.2-0.1-0.5-0.1-0.7,0
              c-0.4,0.2-0.6,0.8-0.4,1.2c0.1,0.2,0.3,0.4,0.5,0.5c0.5,0.2,1-0.1,1.1-0.5c0,0,0,0,0-0.1c0.2,0,0.4-0.1,0.6-0.2
              c0.3-0.1,0.6-0.2,1-0.3c0,0,0.1,0,0.1,0.1c0.2,0.3,0.5,0.5,0.7,0.8c0.4,0.4,0.8,0.9,1.1,1.4c0.2,0.3,0.4,0.5,0.6,0.7
              c0,0,0,0.1,0.1,0.1v0c0.1,0.1,0.1,0.3,0,0.4l0.1,0.1l-0.1-0.1c-0.3,0.2-0.6,0.5-0.8,0.8c-0.5,0-0.8,0.4-0.8,0.9
              c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-0.1,0-0.1,0-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.3,0.5-0.4c0,0,0,0,0.1,0
              c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0.1,0.2,0.1c0.4,0.2,0.7,0.5,0.9,0.9v0
              c0,0.4,0.3,0.8,0.7,0.9c0.5,0.1,1-0.1,1.1-0.6c0.1-0.5-0.1-1-0.6-1.1c-0.4-0.3-0.7-0.5-1.1-0.8c-1.1-0.7-2-1.3-2.1-2.1
              c0.1,0,0.1-0.1,0.2-0.1c0.2-0.1,0.4-0.1,0.6-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.7-0.2,0.8-0.6
              c0.2-0.5-0.1-1-0.5-1.2c-0.5-0.2-1,0.1-1.2,0.5c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.4,0.1c-0.4,0.1-0.5,0.1-0.9-0.3l0,0
              c-0.2-0.2-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.2l-0.2-0.2c-0.9-0.9-1.7-2-2.3-3.2c-0.3-0.6-0.3-0.6,0.1-1.1
              c0,0,0.2-0.2,0.3-0.3c0.2-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.4-0.3c0.1,0,0.2,0.1,0.3,0.1c0.5,0,0.8-0.3,0.9-0.8
              c0.1-0.5-0.3-1-0.8-1c-0.5-0.1-1,0.3-1,0.8c-0.3,0.1-0.5,0.3-0.8,0.5c-0.2,0.1-0.5,0.3-0.7,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
              c-0.5-0.5-0.6-1.2-0.4-1.8c0.5-0.8,1.3-1.3,2.2-1.5l0.3-0.1c1.6-0.7,3.4-0.8,5.2-0.5c0.5,0.1,1,0.2,1.5,0.4
              c0.6,0.2,1.1,0.3,1.7,0.4c0.7,0.2,1.4,0.6,1.9,1.2c-0.1,0-0.2,0.1-0.3,0.1l-0.2,0.1l-0.2,0.1c-0.2-0.1-0.4-0.2-0.6-0.2
              c-0.4,0-0.7,0.2-0.8,0.6c-0.2,0.5,0.1,1,0.6,1.1c0.5,0.2,1-0.1,1.1-0.6c0.3-0.1,0.6-0.2,0.9-0.3c0,0,0.1,0,0.2,0
              c0.1,0.1,0.2,0.1,0.3,0.2l0,0c0.5,0.6,0.9,1,1.2,1.2c0.4,0.4,0.7,0.7,1.3,1.5c0.1,0.4,0,0.8-0.2,1.1l-0.1,0.2
              c-0.3,0.7-0.7,1.4-1.1,2.1c-0.3,0.5-0.3,0.5-0.9,0.3l-0.2,0c-0.5-0.2-1.1-0.4-1.6-0.6l-0.1,0c-0.2-0.1-0.4-0.3-0.6-0.5
              c-0.7-1.1-1.4-2.2-2.3-3.6c-0.3-0.4-0.4-0.9-0.2-1.3c0-0.2,0.1-0.4,0.1-0.5c0.3-0.2,0.5-0.5,0.5-0.8c0-0.5-0.4-0.9-0.9-0.9
              c-0.5,0-0.9,0.4-0.9,0.9c0,0.2,0.1,0.4,0.2,0.5c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3
              c-0.1,0.4-0.1,0.5-0.4,0.5c-0.2,0-0.5,0-0.7,0c-0.2,0-0.5,0-0.7,0c0,0,0,0-0.1-0.1c-0.4-0.3-0.9-0.3-1.3,0c-0.3,0.4-0.3,0.9,0,1.3
              c0.4,0.3,0.9,0.3,1.3,0c0,0,0,0,0,0c0.1,0,0.3,0,0.6,0c0.2,0,0.4,0,0.5,0c0.6-0.1,1.1,0.3,1.2,0.9c0.1,0.2,0.2,0.4,0.4,0.6
              c0.1,0.2,0.2,0.3,0.4,0.5c0.5,0.6,0.9,1.4,1.1,2.2c0,0.3-0.1,0.5-0.1,0.8c-0.1,0-0.2,0.1-0.3,0.1c-0.4,0.3-0.5,0.9-0.2,1.2
              c0.3,0.4,0.9,0.5,1.3,0.2c0.4-0.3,0.5-0.8,0.2-1.2c0.1-0.2,0.2-0.3,0.3-0.4c0.6,0.2,1.2,0.4,1.8,0.8c0.7,0.4,0.8,0.5,0.8,1
              c0.3,2.1-0.2,4.2-1.2,6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.2,0.4-0.3,0.6c-0.4,0.3-0.9,0.4-1.4,0.2c-0.2,0-0.4,0-0.5,0
              c-0.5,0-0.8,0-1,0c-0.2-0.2-0.4-0.2-0.6-0.2c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.2,0,0.4-0.1,0.6-0.2
              c0.2,0,0.4,0,0.6,0l0.2,0c0.6,0,2.3,0,2.7,0.6c0.1,0.2,0.2,0.3,0.3,0.4c0,0.1,0,0.1,0,0.2c0,0.4,0.3,0.8,0.8,0.9
              c0.5,0.1,1-0.3,1-0.8c0.1-0.5-0.3-1-0.8-1c-0.1-0.2-0.3-0.4-0.5-0.7c-0.3-0.3-0.3-0.3-0.1-0.7c0.1-0.2,0.2-0.3,0.2-0.4
              c0-0.1,0.1-0.1,0.2-0.4c0,0,0,0,0-0.1c0.1-0.3,0.4-0.4,0.7-0.3c2.6,0.8,5.1,1.7,7.5,2.8c0.6,0.1,1,0.6,1.2,1.1c0,0.1,0,0.2,0.1,0.4
              l0,0.1c0.1,0.4,0.2,0.8,0.2,1.2c0,0.2,0,0.3,0,0.5c0.1,0.4,0.1,0.9,0,1.3c-0.3,0.3-0.7,0.5-1,0.7c-0.5-0.1-0.9,0.2-1,0.6
              c-0.1,0.5,0.2,1,0.6,1.1c0.5,0.1,1-0.2,1.1-0.6c0.1-0.1,0.2-0.2,0.4-0.3c0.4-0.2,0.8-0.2,1.2,0l0.2,0c0.5,0.1,1.1,0.3,1.6,0.4
              l0.1,0c0.4,0.1,0.5,0.2,0.5,0.5c0,0.3,0,0.6,0,0.9c-0.1,0-0.2,0.1-0.3,0.2c-0.4,0.3-0.4,0.9,0,1.3c0.3,0.4,0.9,0.4,1.3,0
              c0.4-0.3,0.4-0.9,0-1.3c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.5,0.2-0.7c0.1-0.5,0.4-1,0.7-1.4l0.1-0.1c0.1-0.2,0.4-0.5,0.6-0.8
              c0.1-0.1,0.2-0.2,0.3-0.4c0.5,0,0.8-0.4,0.8-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9c0,0.1,0,0.1,0,0.2
              c-0.1,0.1-0.2,0.3-0.4,0.5l0,0.1c-0.2,0.3-0.4,0.6-0.5,0.8c-0.1,0-0.1,0.1-0.1,0.1c-0.2,0.2-0.2,0.2-0.4,0.1l-0.4-0.1
              c-0.6-0.2-1.3-0.4-2-0.6c-0.1,0-0.2-0.2-0.3-0.3c-0.5-1.2-0.6-2.5-0.5-3.8c0-0.4,0.2-0.5,0.8-0.7c0.2-0.1,0.4-0.1,0.6-0.1
              c0,0,0,0,0,0c0.5,0.2,1,0,1.2-0.5c0.2-0.5,0-1-0.5-1.2c-0.5-0.2-1,0-1.2,0.5c-0.3,0.1-0.5,0.2-0.8,0.2c-0.6,0.2-1.2,0.2-1.8,0
              c-1.2-0.4-2.4-0.9-3.6-1.4l0,0c-0.8-0.3-1.6-0.7-2.6-1.1l-0.1-0.1c-0.4-0.2-1.1-0.4-1.3-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
              c0-1.5,0.1-3,0.3-4.5c0-0.3,0.1-0.6,0.3-0.8c0.3-0.5,0.5-1,0.7-1.5c0.3-0.7,0.7-1.4,1.1-2.1c0.9-1.5,1.2-1.5,2.2-1.6l0.3,0
              c0.3,0,0.5,0,0.7-0.1c0.4-0.1,0.8-0.1,1.1,0.1c0.4,0.3,0.7,0.8,0.9,1.3l0,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0l-0.2,0
              c0-0.1-0.1-0.1-0.1-0.2c-0.4-0.3-0.9-0.3-1.3,0c-0.3,0.4-0.3,0.9,0,1.3c0.4,0.3,0.9,0.3,1.3-0.1c0.2,0,0.3,0,0.5,0h0
              c0.1,0,0.3,0,0.4,0h0.1c0.5,0,0.5,0,0.6,0.1l0.2,0.3c0.5,0.6,1,1.3,1.3,2l0,0.1c0.2,0.2,0.2,0.5,0,0.8c-0.2,0.5-0.4,1-0.7,1.5
              c-0.1,0.3-0.2,0.3-0.5,0.3c-0.3,0-0.6,0-0.8,0c-0.6,0-1.1-0.2-1.7-0.4c0,0,0,0,0,0c-0.1-0.4-0.2-0.7-0.4-1c0.1-0.1,0.1-0.3,0.1-0.5
              c0-0.4-0.3-0.8-0.7-0.9c-0.5-0.1-1,0.1-1.1,0.6c-0.1,0.5,0.2,1,0.6,1.1c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.2,0.1,0.2
              c0.1,0.2,0.2,0.5,0.2,0.7c0.1,0.4,0,0.9-0.1,1.3l-0.1,0.3c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.4,0.3-0.5,0.8-0.3,1.2
              c0.3,0.4,0.8,0.5,1.2,0.3c0.4-0.3,0.5-0.8,0.3-1.2c0.1-0.2,0.1-0.4,0.2-0.7l0.1-0.4c0-0.2,0.1-0.2,0.6-0.1c1.6,0.2,3.1,0.8,4.4,1.8
              c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.2,0.3,0.2,0.4c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.4,0.3,0.8,0.8,0.9c0.5,0.1,0.9-0.2,1-0.7
              s-0.2-1-0.7-1c-0.1-0.2-0.1-0.4-0.2-0.6c0.5-0.3,1-0.6,1.6-0.7c0.7-0.2,1.4,0,1.9,0.5c0.3,0.3,0.6,0.6,0.9,0.8
              c0.4,0.3,0.8,0.8,1.1,1.2c0.2,0.5,0.3,1,0.3,1.5c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.4-0.1,1,0.3,1.2c0.4,0.3,1,0.1,1.2-0.3
              c0.3-0.4,0.1-1-0.3-1.2c0-0.1,0-0.3-0.1-0.4l0-0.1c0,0,0-0.1,0-0.1c0-0.4,0-0.7,0.1-1.1c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1,0.1
              c0.2,0.2,0.4,0.4,0.6,0.7c0.7,1.4,1.1,3,1.2,4.5c0,0.5,0.1,1,0.1,1.4c0,0.5,0,1,0.1,1.6c0,0.3,0.1,0.6,0.1,0.9v0
              C81,45.2,81,46.4,80.8,47.7z M58.1,58.9c-0.1-0.5-0.6-0.8-1.1-0.7c-0.5,0.1-0.8,0.6-0.6,1.1c-0.1,0.1-0.3,0.2-0.4,0.4
              c-0.2,0-0.4-0.1-0.6-0.3c-0.1,0-0.2-0.1-0.3-0.1c-1.5-0.8-2.6-1.5-3.5-2.1c-0.5-0.1-0.7-0.6-0.7-1v0c0.1-0.1,0.2-0.2,0.3-0.3
              c0.2-0.4,0.1-1-0.4-1.2c-0.2-0.1-0.5-0.1-0.7-0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2,0.4-0.1,1,0.4,1.2c0,0.1,0.1,0.3,0.1,0.5v0
              c-2.9-0.5-5.6-1.3-8.3-2.5l-0.1-0.1c-0.3-0.1-0.3-0.1-0.6-1.1c0-0.1-0.1-0.2-0.1-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.5,0-1-0.5-1.2
              c-0.5-0.2-1,0-1.2,0.5c-0.2,0.5,0,1,0.5,1.2c0,0.1,0.1,0.2,0.1,0.4c0.2,0.6,0.2,0.7-0.1,1c0,0-0.1,0.1-0.2,0.1
              c-0.2-0.1-0.5-0.2-0.8-0.1c-0.5,0.2-0.7,0.7-0.6,1.1c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.5,0.1,0.7,0.1c0.3-0.1,0.6-0.4,0.6-0.8
              l0.3-0.1c0.5-0.2,0.5-0.2,1.3,0.2l0.1,0c0.7,0.3,1.5,0.6,2.3,0.9c-0.2,0.3-0.4,0.5-0.7,0.7c-0.5-0.2-1,0-1.2,0.5
              c-0.2,0.5,0,1,0.5,1.2c0.2,0.1,0.5,0.1,0.7,0.1c0.4-0.1,0.6-0.4,0.6-0.8l0.4-0.3c0.2-0.2,0.5-0.4,0.8-0.6l0.1,0
              c0.1-0.1,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1,0l0.1,0c1.2,0.4,2.4,0.7,3.7,0.9c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5,0.1,0.7,0.2
              c1,0.6,1.9,1.1,2.8,1.6l0.9,0.5c0.4,0.1,0.7,0.4,0.9,0.7c0,0.2,0,0.4,0.1,0.6c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.4-0.1,1,0.4,1.2
              c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0c0.5-0.1,0.7-0.6,0.6-1.1c-0.1-0.2-0.2-0.5-0.5-0.6c0-0.2,0-0.5,0.1-0.7
              c0.1-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.2-0.1,0.4-0.2,0.5-0.4
              C58.1,59.4,58.2,59.2,58.1,58.9z M79.2,43.7c-0.1-0.5-0.6-0.8-1.1-0.6c-0.2,0.1-0.4,0.2-0.5,0.4h-1c-0.1,0-0.2,0.1-0.3,0.2
              c-0.3,2-1.1,3.9-2.5,5.4l-0.2,0.3c0,0-0.1,0-0.1,0c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2,0.4-0.1,1,0.4,1.2c0.1,0.1,0.3,0.1,0.4,0.1
              c0.1,0,0.2,0,0.3,0c0.5-0.1,0.7-0.6,0.6-1.1c0,0,0-0.1-0.1-0.2c1.4-1.5,2.3-3.3,2.7-5.2c0,0,0-0.1,0-0.1h0c0,0,0.1,0,0.2,0
              c0.1,0,0.2,0,0.2,0c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.3,0C79.1,44.7,79.3,44.2,79.2,43.7z"/>
          </g>
        </svg>
    </div>
  );
}