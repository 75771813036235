import React, { useEffect, useState } from 'react';
import _ from "lodash";
import Papa from 'papaparse'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ScrollToTop } from 'utils/ScrollToTop';

// Utils
import { getData } from 'utils/sanity/'

// Queries
import { donateQuery } from "utils/sanity/sanity-queries"


// Sections
import { PageHero } from 'components/PageHero'
import { PageOverhang } from 'components/PageOverhang';
import { Subscribe } from 'components/Subscribe'
import { Loader } from 'components/Loader';

// Images
import waveAlpha from 'img/wave-alpha.png'
import waveBeta from 'img/wave-beta.png'
import waveGamma from 'img/wave-gamma.png'
import waveTheta from 'img/wave-theta.png'
import waveLow from 'img/wave-low.png'


// Style
import './index.scss'
import { DonorListSection } from './DonorListSection';
import { DonationSection } from './DonationSection';


export const Donate = () => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [donors, setDonors] = useState([]);

  const LIVEPK = 'pk_live_xwRURRHwpEgfGA5pqYVFct9m'

  const stripePromise = loadStripe(LIVEPK);

  function parseDonors(file) {
    const config = {
      delimiter: ",",  // auto-detect
      newline: "",  // auto-detect
      quoteChar: '"',
      escapeChar: '"',
      header: true,
      step: undefined,
      complete: (results, file) => {
        setDonors(_.groupBy(results.data, "Category"))
      },
      download: true,
      skipEmptyLines: true,
      transform: undefined,
      transformHeader: function(h) { 
        // Remove the parentheses from the header
        return h.replace(/ *\([^)]*\) */g, ""); 			
      }
    }
    
    Papa.parse(file, config)
  }

  useEffect(()=>{
    parseDonors('https://docs.google.com/spreadsheets/d/e/2PACX-1vT7OBdcIjPVWFD0rWZAJ_cK5dAoS0Qv6ruB18QzcOQC3y0R6uE9bA5mLqKEcZj3vZ_44mUUopqWsDzt/pub?gid=374023597&single=true&output=csv')
  },[])

  const donorSections = [
    {
      title: 'Platinum Donors $1000',
      nickname: 'OpenBCI Hero',
      image: waveGamma,
      button: {
        text: 'Be the first!',
        priceId: 'price_1JYBHVErb6Bpkgcn2KLokQJK'
      },
      donors: !donors.Platinum ? [{Name: "Your name or your company's name here!"}] : donors.Platinum
    },
    {
      title: 'Gold Donors $100',
      nickname: 'Brain-Computer Interface Rockstar',
      image: waveBeta,
      button: {
        text: 'Donate $100',
        priceId: 'price_1JYBHVErb6Bpkgcnq9Nk8lbr'
      },
      donors: !donors.Gold ? [{Name: "Loading..."}] : donors.Gold
    },
    {
      title: 'Silver Donors $50',
      nickname: 'Open-Source Super Supporter',
      image: waveAlpha,
      button: {
        text: 'Donate $50',
        priceId: 'price_1JYBHVErb6Bpkgcn0iO0NEC3'
      },
      donors: !donors.Silver ? [{Name: "Loading..."}] : donors.Silver
    },
    {
      title: 'Bronze Donors $20',
      nickname: 'OpenBCI Hero',
      image: waveTheta,
      button: {
        text: 'Donate $20',
        priceId: 'price_1JaQPUErb6BpkgcntdWrHWcU'
      },
      donors: !donors.Bronze ? [{Name: "Loading..."}] : donors.Bronze
    },
    {
      title: '',
      nickname: 'Every little bit counts!',
      image: waveLow,
      button: {
        text: 'Donate today!',
        priceId: 'price_1JYBP1Erb6Bpkgcnaw9slJBD'
      },
      donors: !donors.Any ? [{Name: "Loading..."}] : donors.Any
    },
  ]

  const donationButtons = [
    {
      cost: 5,
      sku: 'price_1JYBP1Erb6Bpkgcnaw9slJBD'
    },
    {
      cost: 20,
      sku: 'price_1JaQPUErb6BpkgcntdWrHWcU'
    },
    {
      cost: 50,
      sku: 'price_1JYBHVErb6Bpkgcn0iO0NEC3'
    },
    {
      cost: 100,
      sku: 'price_1JYBHVErb6Bpkgcnq9Nk8lbr'
    },
    {
      cost: 200,
      sku: 'price_1JaPsqErb6Bpkgcng8cD6StF'
    },
  ]
  
  // Get the data from Sanity
  useEffect(() => {
    async function fetchData() { 
      try {
        const dataResponse = await getData(donateQuery)
        setData(dataResponse)
        setLoaded(true)
      }
      catch (err) {
        console.log('fetch failed', err);
        throw err
      }
    }
    fetchData()
  }, []);

    // Set the page title once
    useEffect(() => {
      document.title = "OpenBCI | Donate"
    }, [])

  return (!loaded ?
    <Loader /> 
    :
    <div className={`donate-page page-container`} >
      <ScrollToTop />
      <PageHero 
        eyebrow={data.hero.heroEyebrow} 
        header={data.hero.heroHeader}
        image={data.hero.heroImg}
        tall={false}
      />
      <section className="page-content-wrapper">
        <PageOverhang 
          title={data.pageOverhang.title}
          body={data.pageOverhang.body}
          sidenote={data.pageOverhang.sidenote}
        />
        <Elements stripe={stripePromise} className="stripe-donate-wrapper">
        <DonationSection 
          donationButtons={donationButtons}
        />
        <div className='page-content-container'>
          <div className='page-content-header'>
            <h2 className='page-content-header-title' >Thank you!</h2>
            <p className='page-content-header-subtitle' >Be a donor and a part of our wonderful community.</p>
            <div className='rule-orange'></div>
          </div>
          {donorSections.map(({image, title, nickname, button, donors}) =>           
            <DonorListSection 
              image={image}
              title={title}
              nickname={nickname}
              button={button}
              // Performing the function in the props is less than ideal, but works for now
              // This function pulls the Name keys out of the donor objects and removes blank rows
              donors={_.map(donors, 'Name').filter(Boolean)}
              key={title}
            />
          )}
        </div>
        </Elements>
        <Subscribe />
      </section>
    </div>
  )
}