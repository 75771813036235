import React, { useEffect, useState } from 'react';
import { ScrollToTop } from 'utils/ScrollToTop';

// Sections
// import { Loader } from 'components/Loader';
import { PageHero } from 'components/PageHero';
import { PageOverhang } from 'components/PageOverhang';

// Images
import hero from 'img/hero-newsletter.png'

// Style
import './index.scss'

const NewsletterForm = ({ onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <div className='newsletter-form' >
      <div className='newsletter-primary-inputs'>
        <input
          ref={node => (email = node)}
          type="email"
          placeholder="Email"
          name="EMAIL" 
        />
      </div>

      <div className='newsletter-permissions'>
        <p>You can unsubscribe at any time by clicking the link in the footer of our emails. For information about our privacy practices, please visit <a href='https://docs.openbci.com/FAQ/Privacy/'>https://docs.openbci.com/FAQ/Privacy/</a>.</p>
        <p>We use Klaviyo as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Klaviyo for processing. Learn more about <a href='https://klaviyo.com/legal/privacy/'>Klaviyo's privacy practices</a> here.</p>
      </div>
      <button className='button' onClick={submit}>
        Subscribe
      </button>
    </div>
  );
};

export const Newsletter = () => {
  const [status, setStatus] = useState(null);

  const submitSubscription = (formData) => {
    setStatus('sending');

    fetch("https://a.klaviyo.com/client/subscriptions/?company_id=R4PLHb", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        revision: '2024-02-15'
      },
      body: JSON.stringify({
        data: {
          type: 'subscription',
          attributes: {
            custom_source: 'Newsletter Page',
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  email: formData.EMAIL,
                }
              }
            }
          },
          relationships: {list: {data: {type: 'list', id: 'UNtyn3'}}} // https://www.klaviyo.com/list/UNtyn3
        }
      })
    })
    .then(() => {
      setStatus('success');
    }).catch(() => {
      setStatus('error');
    });
  }

  // Set the page title once
  useEffect(() => {
    document.title = "OpenBCI | Newsletter"
  }, [])
  
  return ( 
    // !loaded ?
    // <Loader /> 
    // :
    <div className={`contact`} >
      <ScrollToTop />
      <PageHero 
        eyebrow="Newsletter"
        header="Stay up-to-date!"
        image={hero}
        hasButton={false}
        tall={false}
      />
      <section className="page-content-wrapper">
        <PageOverhang 
          title="OpenBCI Newsletter Signup"
          body={[{
            "style": "h3",
            "_type": "block",
            "markDefs": [],
            "children": [
              {
                "_type": "span",
                "text": "Join the OpenBCI Community and get newsletter-exclusive discounts",
                "marks": []
              }
            ]
          }]}
        />
        <div className='newsletter-form-wrapper'>
          <NewsletterForm
            onValidated={formData => submitSubscription(formData)}
          />
          <div className="subscribe-status-message" >
            {status === "sending" && <p style={{ color: "blue" }}>Sending...</p>}
            {status === "error" && <p style={{ color: "red" }}>Error</p>}
            {status === "success" && <p style={{ color: "green" }}>Thank you for subscribing!</p>}
          </div>
        </div>
      </section>
    </div>
  )
}