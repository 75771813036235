import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from "lodash";
import { ScrollToTop } from 'utils/ScrollToTop';

// Utils
import { getData } from 'utils/sanity/'

// Queries
import { careersQuery } from "utils/sanity/sanity-queries"

// Sections
import { CommunityGrid } from 'pages/Home/CommunityGrid'
import { Subscribe } from 'components/Subscribe'
import { Loader } from 'components/Loader';
import { PageHero } from 'components/PageHero';
import { PageOverhang } from 'components/PageOverhang';

// Style
import './index.scss'

export const Careers = () => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [careerKeys, setCareerKeys] = useState([]);


  // Get the data from Sanity
  useEffect(() => {
    async function fetchData() { 
      try {
        const dataResponse = await getData(careersQuery)
        dataResponse.careersList = _.groupBy(dataResponse.careers, "field")
        setData(dataResponse)
        setLoaded(true)
        document.title = `OpenBCI | Jobs`
      }
      catch (err) {
        console.log('fetch failed', err);
        throw err
      }
    }
    fetchData()
  }, []);

  useEffect(()=>
  setCareerKeys(_.keys(data.careersList))
  ,[data.careersList])

  return ( !loaded ?
    <Loader /> 
    :
    <div className={`careers-page`} >
      <ScrollToTop />
      <PageHero 
        eyebrow={data.hero.heroEyebrow} 
        header={data.hero.heroHeader}
        image={data.hero.heroImg}
        hasButton={data.hero.hasButton}
        button={data.hero.button}
        tall={false}
      />
      <section className="page-content-wrapper">
        <PageOverhang 
          title={data.pageOverhang.title}
          body={data.pageOverhang.body}
          sidenote={data.pageOverhang.sidenote}
        />
        {data.careers[0] && <div className='careers-list-section'>
          <h2>Come innovate with us</h2>
          <p>If you like to chart new territory, collaborate and—above all—keep it real, let’s talk.</p>
          <div className='rule-orange' />
          <div className='careers-list-container'>
            {careerKeys.map((field, i) => 
              <div className='careers-list' key={i}>
                <h5 className='careers-list-title'>{field}</h5>
                {data.careersList[field].map(({position, level, location, slug}, i) => 
                  <div className='career-row' key={i} >
                    <div className='career-title'><p><span>{position}</span> {level &&`/ ${level}`}</p></div>
                    <div className='career-location'>{location}</div>
                    <div className='career-actions'>
                      <Link className='button' to={`/careers/${slug}`}>Learn more</Link>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div> 
        </div> }
      </section>
      {data.fetchedCommunityGrid.communityGrid.title && <CommunityGrid 
        title={data.fetchedCommunityGrid.communityGrid.title}
        eyebrow={data.fetchedCommunityGrid.communityGrid.eyebrow} 
        communityPosts={data.fetchedCommunityGrid.communityGrid.homeCommunityPosts}
      />
      }
      <div className='page-content-wrapper'>
        <Subscribe />
      </div>
    </div>
  )
}