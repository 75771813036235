import React, { useState } from 'react';

const PageStateContext = React.createContext({});

const PageStateProvider = (props) => {
  const [pageLoaded, setPageLoaded] = useState(false);

  return (
    <PageStateContext.Provider value={{ pageLoaded, setPageLoaded }}>
      {props.children}
    </PageStateContext.Provider>
  );
}

export { PageStateContext, PageStateProvider };