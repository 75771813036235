import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./index.scss";

export function Search({ active }) {
  const location = useLocation();
  return (
    <>
      <div
        className={`search-container ${active ? "active" : ""}`}
        style={{ paddingTop: 68 }}
      >
        <Helmet>
          {" "}
          <script
            async
            src="https://cse.google.com/cse.js?cx=79418c68704bc1d3c"
          ></script>

        </Helmet>
        <div className="gcse-searchbox-only"></div>
      </div>
    </>
  );
}
