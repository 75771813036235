import React, { useEffect, useState } from 'react'
import Slider from "react-slick"
import { useWindowSize } from 'react-use'


// Style
import './index.scss'
import { BodyContent } from 'components/Sanity'

export const Testimonials = ({testimonials, loaded}) => {
  let { width } = useWindowSize();
  const  [numberOfTestimonials, setNumberOfTestimonials] = useState(1)

  useEffect(()=> {
    if (width < 1000) {
      setNumberOfTestimonials(1);
    } else if (width > 1000) {
      setNumberOfTestimonials(2);
    } 
    }, [width])

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: numberOfTestimonials,
    slidesToScroll: 1,
    arrows: width > 700,
    autoplay: width < 700
  };

  return (
        <section className='testimonials' >
          <h2 className='testimonial-header'>testimonials from our <br />community and customers</h2>
          <Slider className='testimonial-slider' {...settings}>
            {loaded && testimonials.map((testimonial, i) =>
              <div className='testimonial' key={i}>
                <img src={ `${testimonial.image}?fit=crop&h=200&w=200` } alt='reviewer profile' className='testimonial-image' />
                <div className='testimonial-content'>
                  <div className='eyebrow'>{testimonial.eyebrow}</div>
                  <h2>{testimonial.name}</h2>
                  <div className='rule-orange' />
                  <BodyContent blocks={testimonial.body}/>
                </div>
              </div>
            )}
          </Slider>
        </section>
  )
}