import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { getAppContainerID } from 'utils/env-utils'
import TagManager from 'react-gtm-module'

// Components & Modules
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import gsap from 'gsap';
import { GSDevTools } from 'gsap/GSDevTools';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import drawSVG  from "gsap/DrawSVGPlugin";
import CookieConsent, {  getCookieConsentValue, Cookies} from "react-cookie-consent";
import {useMedia} from 'react-use';

// Contexts
import { PageStateProvider } from 'contexts/PageStateContext'
import {  ModalManager } from 'contexts/ModalManager'

import { Header } from 'components/Header';
import { Footer } from 'components/Footer';

// Pages
import { Home } from './pages/Home';
import { Citations } from 'pages/Citations';
import { Careers } from 'pages/Careers';
import { CareerPage } from 'pages/CareerPage';
import { Downloads } from './pages/Downloads';
import { Donate } from './pages/Donate';
import { CaseStudies } from 'pages/CaseStudies';
import { CaseStudyPage } from 'pages/CaseStudyPage';
import { Newsletter } from 'pages/Newsletter';
import { Page } from './components/Page';
import { About } from './pages/About';
import { SearchResults } from 'pages/SearchResults';

// Style
import 'style/index.scss';
// Slick carousel CSS files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import { CaseStudiesProvider } from 'contexts/CaseStudiesContext';

//Tag manager code
const tagManagerArgs = {
gtmId: 'GTM-5MNP6L3Q'
}
TagManager.initialize(tagManagerArgs)

export const App = () => {
  const isWide = useMedia('(min-width: 480px)');
  
  //Tag manager pageview
  window.dataLayer.push({
    event: 'pageview'
  });
  
  if (typeof window !== `undefined`) {
    gsap.registerPlugin(SplitText, ScrollTrigger, GSDevTools, drawSVG)
    gsap.core.globals("SplitText", SplitText, "ScrollTrigger", ScrollTrigger, "drawSVG", drawSVG)
  }
  useEffect(()=> {
    // kick off the polyfill!
    smoothscroll.polyfill();
  }, [])

      const handleDeclineCookie = () => {
      //remove google analytics cookies
      Cookies.remove("_ga");
      Cookies.remove("_gat");
      Cookies.remove("_gid");
    };
    
    useEffect(() => {
      const isConsent = getCookieConsentValue();
      if (isConsent === "true") {
        handleAcceptCookie();
      }
    }, []);
    
    const handleAcceptCookie = () => {
      if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      }
    };

  return (
      <PageStateProvider>
        <CaseStudiesProvider>
          <ModalManager>
            <Router>
              <Header />
              <Routes>
                <Route index element={ <Home /> }/>
                <Route path='/about' element={ <About />}/>
                <Route path='/about/:slug' element={ <About />}/>
                <Route path='/careers' element={ <Careers />}/>
                <Route path='/jobs' element={ <Careers />}/>
                <Route path="/careers/:slug" element={ <CareerPage/>}/>
                <Route path="/jobs/:slug" element={ <CareerPage/>}/>
                <Route path='/citations' element={ <Citations />}/>
                <Route path='/downloads' element={ <Downloads />}/>
                <Route path='/donate' element={ <Donate />}/>
                <Route path='/newsletter' element={ <Newsletter />}/>
                <Route path="/casestudies" element={ <CaseStudies/>}/>
                <Route path="/casestudies/:slug" element={ <CaseStudyPage/>}/>
                <Route path='/search-results/' element={ <SearchResults />}/>
                <Route path="/:slug" element={ <Page/>}/>
                <Route path="*" element={ <Navigate to='/' replace /> }/>
              </Routes>
              <Footer />
              <CookieConsent
                location="bottom"
                buttonText="Accept"
                declineButtonText="Decline"
                disableStyles
                expires={150}
                contentClasses="cookie-consent-text"
                buttonWrapperClasses="cookie-consent-buttons"
                buttonClasses="button"
                declineButtonClasses="cookie-consent-decline"
                enableDeclineButton
                onAccept={handleAcceptCookie}
                onDecline={handleDeclineCookie}
              >
                {isWide ? <span>OpenBCI uses <a href="https://docs.openbci.com/FAQ/Cookie/" className='cookie-link'>cookies</a>. We use them for advertising, analytics, and maintaining a well-functioning website.</span> : <span>OpenBCI uses <a href="https://docs.openbci.com/FAQ/Cookie/" className='cookie-link'>cookies</a>.</span>}{" "}
              </CookieConsent>
            </Router>
          </ModalManager>
        </CaseStudiesProvider>
      </PageStateProvider>
  )
}

window.onload = () => {
    ReactDOM.render(
      <App />,
      document.getElementById(getAppContainerID())
    )
  }