import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet"

// Style
import './index.scss'

export const SearchResults = () => {
  
    return (
      <div className={`search-results-container`}>
        <section className="page-content-wrapper">
        <Helmet> <script async src="https://cse.google.com/cse.js?cx=79418c68704bc1d3c"></script> </Helmet>
        <div className="gcse-searchresults-only"></div>
        </section>
      </div>
  )
}